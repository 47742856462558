import { AuthModule } from './auth/auth.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DataTablesModule } from 'angular-datatables';
import { NgImageSliderModule } from 'ng-image-slider';
import { MatTabsModule } from '@angular/material/tabs';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { MatStepperModule } from '@angular/material/stepper';
import { Wm4G9kCustomReportComponent } from './Forms/wm4-g9k-custom-report/wm4-g9k-custom-report.component';
import { AuditReportComponent } from './Forms/audit-report/audit-report.component';
import { ReportIndexComponent } from './AutoMailReport/report-index/report-index.component';
import { PReportComponent } from './AutoMailReport/preport/preport.component';

@NgModule({
  declarations: [AppComponent, routingComponents, Wm4G9kCustomReportComponent, AuditReportComponent, ReportIndexComponent, PReportComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DataTablesModule,
    AppRoutingModule,
    AuthModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatTabsModule,
    NgxSpinnerModule,
    NgImageSliderModule,
    VgCoreModule,
    MatStepperModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
