import { PCustomMonthlyReportComponent } from './Forms/p-custom-monthly-report/p-custom-monthly-report.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { RandomGuard } from './auth/guards/random.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserGuard } from './auth/guards/user.guard';

import { LoginComponent } from './LobbyScreen/login/login.component';
import { FooterComponent } from './footer/footer.component';
import { CreateAccountComponent } from './LobbyScreen/create-account/create-account.component';
import { ExperinceComponent } from './LobbyScreen/experince/experince.component';
import { DashboardComponent } from './Forms/dashboard/dashboard.component';
import { LobbyComponent } from './LobbyScreen/lobby/lobby.component';
import { DeviceComponent } from './Forms/device/device.component';
import { AttendanceComponent } from './Forms/attendance/attendance.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ShopComponent } from './Forms/shop/shop.component';
import { HelpComponent } from './Forms/help/help.component';
import { ReportComponent } from './Forms/report/report.component';
import { KReportComponent } from './Forms/k-report/k-report.component';
import { KDashboardComponent } from './Forms/k-dashboard/k-dashboard.component';
import { OCardComponent } from './Forms/o-card/o-card.component';
import { OReportComponent } from './Forms/o-report/o-report.component';
import { ODashboardComponent } from './Forms/o-dashboard/o-dashboard.component';
import { EntryPageComponent } from './Forms/entry-page/entry-page.component';
import { OtpVerificationComponent } from './LobbyScreen/otp-verification/otp-verification.component';
import { MonthlyReportComponent } from './Forms/monthly-report/monthly-report.component';
import { UserGuideComponent } from './Forms/user-guide/user-guide.component';
import { LitePlusDashboardComponent } from './Forms/lite-plus-dashboard/lite-plus-dashboard.component';
import { LitePlusReportComponent } from './Forms/lite-plus-report/lite-plus-report.component';
import { LitePlusCardComponent } from './Forms/lite-plus-card/lite-plus-card.component';
import { ONewReportComponent } from './Forms/o-new-report/o-new-report.component';
import { CustomReportComponent } from './Forms/p-custom-report/p-custom-report.component';
import { Wm4G9kCustomReportComponent } from './Forms/wm4-g9k-custom-report/wm4-g9k-custom-report.component';
import { AuditReportComponent } from './Forms/audit-report/audit-report.component';
import { ReportIndexComponent } from './AutoMailReport/report-index/report-index.component';
import { PReportComponent } from './AutoMailReport/preport/preport.component';

const routes: Routes = [
  {
    path: '',
    component: LobbyComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
  },
  {
    path: 'signup',
    component: CreateAccountComponent,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
  },
  {
    path: 'verify-otp',
    component: OtpVerificationComponent,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
  },
  {
    path: 'experinceSignUp',
    component: ExperinceComponent,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
  },
  {
    path: 'home',
    component: EntryPageComponent,
    canActivate: [RandomGuard],
    canLoad: [RandomGuard],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [RandomGuard, UserGuard],
    canLoad: [RandomGuard],
  },
  {
    path: 'dashboard-k',
    component: KDashboardComponent,
    canActivate: [RandomGuard, UserGuard],
    canLoad: [RandomGuard, UserGuard],
  },
  {
    path: 'dashboard-o',
    component: ODashboardComponent,
    canActivate: [RandomGuard, UserGuard],
    canLoad: [RandomGuard, UserGuard],
  },
  {
    path: 'dashboard-lite-plus',
    component: LitePlusDashboardComponent,
    canActivate: [RandomGuard, UserGuard],
    canLoad: [RandomGuard, UserGuard],
  },
  {
    path: 'device',
    component: DeviceComponent,
    canActivate: [RandomGuard],
    canLoad: [RandomGuard],
  },
  {
    path: 'attendance',
    component: AttendanceComponent,
    canActivate: [RandomGuard, UserGuard],
    canLoad: [RandomGuard, UserGuard],
  },
  {
    path: 'report',
    component: ReportComponent,
    canActivate: [RandomGuard, UserGuard],
    canLoad: [RandomGuard, UserGuard],
  },
  {
    path: 'custom-report',
    component: CustomReportComponent,
    canActivate: [RandomGuard, UserGuard],
    canLoad: [RandomGuard, UserGuard],
  },
  {
    path: 'wm4G9k-custom-report',
    component: Wm4G9kCustomReportComponent,
    canActivate: [RandomGuard, UserGuard],
    canLoad: [RandomGuard, UserGuard],
  },
  {
    path: 'custom-monthly-report',
    component: PCustomMonthlyReportComponent,
    canActivate: [RandomGuard, UserGuard],
    canLoad: [RandomGuard, UserGuard],
  },
  {
    path: 'k-report',
    component: KReportComponent,
    canActivate: [RandomGuard, UserGuard],
    canLoad: [RandomGuard, UserGuard],
  },
  {
    path: 'o-report',
    component: OReportComponent,
    canActivate: [RandomGuard, UserGuard],
    canLoad: [RandomGuard, UserGuard],
  },
  {
    path: 'o-new-report',
    component: ONewReportComponent,
    canActivate: [RandomGuard, UserGuard],
    canLoad: [RandomGuard, UserGuard],
  },
  {
    path: 'lite-plus-report',
    component: LitePlusReportComponent,
    canActivate: [RandomGuard, UserGuard],
    canLoad: [RandomGuard, UserGuard],
  },
  {
    path: 'monthlyReport',
    component: MonthlyReportComponent,
    canActivate: [RandomGuard, UserGuard],
    canLoad: [RandomGuard, UserGuard],
  },
  {
    path: 'o-cards',
    component: OCardComponent,
    canActivate: [RandomGuard, UserGuard],
    canLoad: [RandomGuard, UserGuard],
  },
  {
    path: 'lite-plus-cards',
    component: LitePlusCardComponent,
    canActivate: [RandomGuard, UserGuard],
    canLoad: [RandomGuard, UserGuard],
  },
  {
    path: 'userGuide',
    component: UserGuideComponent,
    canActivate: [RandomGuard],
    canLoad: [RandomGuard],
  },
  {
    path: 'shop',
    component: ShopComponent,
    canActivate: [RandomGuard],
    canLoad: [RandomGuard],
  },
  {
    path: 'auditReport',
    component: AuditReportComponent,
    canActivate: [RandomGuard, UserGuard],
    canLoad: [RandomGuard, UserGuard],
  },
  {
    path: 'help',
    component: HelpComponent,
    canActivate: [RandomGuard],
    canLoad: [RandomGuard],
  },
  //report auto mailer
  {
    path: 'automailer/report',
    component: ReportIndexComponent
  },
  {
    path: 'automailer/Preport',
    component: PReportComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export const routingComponents = [
  LoginComponent,
  CreateAccountComponent,
  ExperinceComponent,
  FooterComponent,
  LobbyComponent,
  DashboardComponent,
  DeviceComponent,
  AttendanceComponent,
  NavbarComponent,
  SidebarComponent,
  ShopComponent,
  HelpComponent,
  ReportComponent,
  CustomReportComponent,
  KReportComponent,
  KDashboardComponent,
  OCardComponent,
  OReportComponent,
  ODashboardComponent,
  EntryPageComponent,
  OtpVerificationComponent,
  MonthlyReportComponent,
  UserGuideComponent,
  LitePlusDashboardComponent,
  LitePlusReportComponent,
  LitePlusCardComponent,
  ONewReportComponent,
  PCustomMonthlyReportComponent,
];
