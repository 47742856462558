import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '../Common/config';
import { UserReg } from '../models/userReg';
import { AllUser } from '../models/allUsers';
import { NameAndId } from '../models/nameAndId';

@Injectable({
  providedIn: 'root',
})
export class AttendanceService {
  allUserDetails: AllUser[];
  allmembers: UserReg[];
  selectedUserDetail: UserReg;
  allNameAndId: NameAndId[];

  constructor(private http: HttpClient) {}

  addRFID(data: any) {
    return this.http.post<any>(`${config.apiUrl}/add-RFID-Verification`, data);
  }

  addUser(data: any) {
    return this.http.post<any>(`${config.apiUrl}/user-regp/add`, data);
  }

  deleteUser(data: any) {
    return this.http.post<any>(`${config.apiUrl}/user-regp/delete`, data);
  }

  updateUser(data: any) {
    return this.http.post<any>(`${config.apiUrl}/user-regp/update`, data);
  }

  getAllUser(data: any) {
    return this.http.post<any>(`${config.apiUrl}/fetchAllUsers`, data);
  }

  getUserDetails(data: any) {
    return this.http.post<any>(`${config.apiUrl}/user-regp`, data);
  }

  getSpecificUser(data: any) {
    return this.http.post<any>(`${config.apiUrl}/fetchSpecificUsers`, data);
  }

  getNameAndId(data: any) {
    return this.http.post<any>(`${config.apiUrl}/fetchNameAndId`, data);
  }

  addShiftUser(data: any) {
    return this.http.post<any>(`${config.apiUrl}/shift-user`, data);
  }
}
