export class Device {
  public SC_Name: string;
  public DId: number;
  public UId: string;
  public Device_MAC: string;
  public Location: string;
  public Device_Model: string;
  public Device_Name: string;
  public Date: string;
  public Time: string;
}
