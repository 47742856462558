import { CreateAccountService } from './../../services/create-account.service';
import { Component, OnInit } from '@angular/core';
import { SignUp } from 'src/app/models/sigup';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Router } from '@angular/router';
import { Chart, registerables } from 'chart.js';
import { ODeviceService } from './../../services/o-device.service';
import { ChangeDetectorRef } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { WeeklyReport } from '../../models/weeklyReport';
import { NgxSpinnerService } from 'ngx-spinner';

Chart.register(...registerables);

@Component({
  selector: 'app-lite-plus-dashboard',
  templateUrl: './lite-plus-dashboard.component.html',
  styleUrls: ['./lite-plus-dashboard.component.css'],
})
export class LitePlusDashboardComponent implements OnInit {
  userData: SignUp;
  now: string;
  date: string;
  totalUsage: Number;
  totalOpportunity: Number;
  todayTotalUsage: Number;
  todayUsageCompelete: Number;
  todayUsageIncompelete: Number;
  todayTotalOpportunity: Number;
  totalCards: Number;
  totalDevice: Number;
  complianceLevel: any;
  chart: any = [];
  chartDailyUsage: any = [];
  weeklyReport: any = [];
  complianceGraphData = [];
  graphLabel = [];
  graphData = [];
  graphDataUsage = [];
  allDevices = [];
  hasMonthlyData: boolean = false;

  constructor(
    private oDeviceService: ODeviceService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private chRef: ChangeDetectorRef,
    private router: Router
  ) {
    this.userData = new SignUp();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.userData = JSON.parse(sessionStorage.getItem(this.authService.USER));
    this.now = new Date()
      .toLocaleTimeString('en-US', { hour12: false })
      .slice(0, 5);
    this.date = new Date().toDateString();
    this.getDashboardData();
    this.getOpportunityAndUsageReport();
    this.getDeviceInformation();
    this.chRef.detectChanges();
    this.spinner.hide();
  }

  getDashboardData() {
    this.oDeviceService
      .getLitePlusDashboardData(this.userData.UId)
      .then((res) => {
        this.spinner.show();
        let {
          Total_Usage,
          Total_Opportunity,
          T_Total_Usage,
          T_Total_Complete,
          T_Total_Incomplete,
          T_Total_Opportunity,
          Total_Cards,
          Total_Device,
          Compliance,
        } = res.data;

        this.totalUsage = Total_Usage || 0;
        this.totalOpportunity = Total_Opportunity || 0;
        this.todayTotalUsage = T_Total_Usage || 0;
        this.todayUsageCompelete = T_Total_Complete || 0;
        this.todayUsageIncompelete = T_Total_Incomplete || 0;
        this.todayTotalOpportunity = T_Total_Opportunity || 0;
        this.totalCards = Total_Cards || 0;
        this.totalDevice = Total_Device || 0;
        this.complianceLevel = Compliance || 0;
        this.complianceLevel = isNaN(this.complianceLevel)
          ? 0
          :this.complianceLevel>100?100:this.complianceLevel;
        this.spinner.hide();
      })
      .catch((error) => {
        this.spinner.show();
        console.log(error);
        this.spinner.hide();
      });
  }

  getOpportunityAndUsageReport() {
    this.oDeviceService
      .getLitePlusOpportunityUsage(this.userData.UId)
      .then((res) => {
        this.spinner.show();
        res = res.data;
        let splitDate: string[];
        let j = 0;
        if (res.length) {
          this.hasMonthlyData = true;
          for (let i = res.length - 1; i >= 0; i--) {
            this.weeklyReport.push(res[i]);
            splitDate = this.weeklyReport[j]['Date'].split('-');
            this.weeklyReport[j]['Date'] = splitDate[2] + '/' + splitDate[1];
            splitDate.slice(0, splitDate.length);

            this.graphLabel.push(this.weeklyReport[j]['Date']);
            this.graphData.push(this.weeklyReport[j]['Total_Opportunity']);
            this.graphDataUsage.push(this.weeklyReport[j]['Total_Usage']);
            let compliance =
              Number(this.weeklyReport[j]['Total_Opportunity']) == 0
                ? 0
                : Number(
                    (
                      (Number(this.weeklyReport[j]['Total_Usage']) /
                        Number(this.weeklyReport[j]['Total_Opportunity'])) *
                      100
                    ).toFixed(2)
                  ) || 0;
            compliance = isNaN(compliance) ? 0 : compliance;
            this.complianceGraphData.push(compliance);
            j = j + 1;
          }
          this.chRef.detectChanges();
          this.fillOpportunityUsageChart(
            this.graphLabel,
            this.graphData,
            this.graphDataUsage
          );
          this.fillOverallComplianceChart(
            this.graphLabel,
            this.complianceGraphData
          );
        }
        this.chRef.detectChanges();
        this.spinner.hide();
      })
      .catch((err) => {
        this.spinner.show();
        console.log(err);
        this.spinner.hide();
      });
    this.spinner.hide();
  }

  fillOpportunityUsageChart(graphLabel, graphData, graphDataUsage) {
    this.chartDailyUsage = new Chart('canvasDailyUsage', {
      type: 'bar',
      data: {
        labels: graphLabel,
        datasets: [
          {
            label: 'Opportunity',
            backgroundColor: 'rgba(0,0,255,0.5)',
            barPercentage: 0.5,
            barThickness: 30,
            maxBarThickness: 50,
            minBarLength: 2,
            hoverBackgroundColor: 'rgba(0,0,255,0.7)',
            borderColor: 'rgba(0,0,255,1)',
            data: graphData,
          },
          {
            label: 'Usage',
            backgroundColor: 'rgba(255,0,0,0.5)',
            barPercentage: 0.5,
            barThickness: 30,
            maxBarThickness: 50,
            minBarLength: 2,
            hoverBackgroundColor: 'rgba(255,0,0,0.7)',
            borderColor: 'rgba(255,0,0,1)',
            data: graphDataUsage,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 25,
            top: 25,
            bottom: 0,
          },
        },
        scales: {
          xAxes: {
            title: {
              text: 'Date',
              display: true,
            },
            time: {
              unit: 'day',
            },
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              maxTicksLimit: 8,
            },
          },
          yAxes: {
            ticks: {
              minRotation: 0,
              maxRotation: 100,
              maxTicksLimit: 6,
              padding: 10,
              // Include a dollar sign in the ticks
              // callback: function(value, index, values) {
              //     return '$' + number_format(value);
              // }
            },
            grid: {
              color: 'rgb(234, 236, 244)',
              drawBorder: false,
              borderDash: [2],
            },
          },
        },
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          tooltip: {
            titleMarginBottom: 10,
            titleColor: '#6e707e',
            titleFont: {
              size: 14,
            },
            backgroundColor: 'rgb(255,255,255)',
            bodyColor: '#858796',
            borderColor: '#dddfeb',
            borderWidth: 1,
            padding: 15,
            displayColors: false,
            caretPadding: 10,
            /* callbacks: {
                    label: function (context) {
                      return `${Number(context.parsed.y)}`;
                    },
                  }, */
          },
        },
      },
    });
  }

  fillOverallComplianceChart(graphLabel, complianceGraphData) {
    this.chart = new Chart('complianceChart', {
      type: 'bar',
      data: {
        labels: graphLabel,
        datasets: [
          {
            label: 'Compliance(%)',
            backgroundColor: '#263070',
            barPercentage: 0.5,
            barThickness: 30,
            maxBarThickness: 50,
            minBarLength: 2,
            hoverBackgroundColor: '#2e59d9',
            borderColor: '#4e73df',
            data: complianceGraphData,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 25,
            top: 25,
            bottom: 0,
          },
        },
        scales: {
          xAxes: {
            title: {
              text: 'Date',
              display: true,
            },
            time: {
              unit: 'day',
            },
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              maxTicksLimit: 8,
            },
          },
          yAxes: {
            title: {
              text: 'Compliance %',
              display: true,
            },
            ticks: {
              minRotation: 0,
              maxRotation: 100,
              maxTicksLimit: 6,
              padding: 10,
              // Include a dollar sign in the ticks
              // callback: function(value, index, values) {
              //     return '$' + number_format(value);
              // }
            },
            grid: {
              color: 'rgb(234, 236, 244)',
              drawBorder: false,
              borderDash: [2],
            },
          },
        },
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          tooltip: {
            titleMarginBottom: 10,
            titleColor: '#6e707e',
            titleFont: {
              size: 14,
            },
            backgroundColor: 'rgb(255,255,255)',
            bodyColor: '#858796',
            borderColor: '#dddfeb',
            borderWidth: 1,
            padding: 15,
            displayColors: false,
            caretPadding: 10,
            callbacks: {
              label: function (context) {
                return `${Number(context.parsed.y)}`;
              },
            },
          },
        },
      },
    });
  }

  getDeviceInformation() {
    this.spinner.show();
    this.oDeviceService
      .getLitePlusDeviceInformation(this.userData.UId)
      .then((res) => {
        if (res.data && res.data.length) {
          this.allDevices = [...res.data];
          this.spinner.hide();
        }
        this.spinner.hide();
      })
      .catch((err) => {
        this.spinner.show();
        console.log(err);
        this.spinner.hide();
      });
  }
}
