import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/services/auth.service';
import { HasDevice } from '../models/hasDevice';
import { ToastrService } from 'ngx-toastr';
import { SignUp } from '../models/sigup';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  userData: SignUp;
  devices: HasDevice;

  constructor(
    private authService: AuthService,
    private toastrService: ToastrService
  ) {
    this.userData = new SignUp();
    this.devices = new HasDevice();
  }

  ngOnInit(): void {
    this.userData = JSON.parse(sessionStorage.getItem(this.authService.USER));
    this.devices = JSON.parse(sessionStorage.getItem(this.authService.DEVICES));
  }

  ComingSoon() {
    this.toastrService.success(
      'This Functionality Will Be Availabe Soon!',
      'Coming Soon!'
    );
  }
}
