import { Injectable } from '@angular/core';
import { HttpClient  } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IpServiceService {

  private GETIPURL: string = "http://api.ipify.org/?format=json";

  constructor(private http:HttpClient) { }

  public getIPAddress()  
  {  
    return this.http.get(this.GETIPURL); 
  }
}
