import { ReportService } from './../../services/report-service.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SignUp } from 'src/app/models/sigup';
import { AuthService } from 'src/app/auth/services/auth.service';
import {
  ReportDetails,
  ReportDetailsFinalized,
} from 'src/app/models/reportDetails';
import { CommonService } from 'src/app/services/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Chart, registerables } from 'chart.js';
import { HasDevice } from '../../models/hasDevice';



Chart.register(...registerables);

const pdfMake = require('pdfmake/build/pdfmake.js');
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-wm4-g9k-custom-report',
  templateUrl: './wm4-g9k-custom-report.component.html',
  styleUrls: ['./wm4-g9k-custom-report.component.css']
})
export class Wm4G9kCustomReportComponent implements OnInit {
  userData: SignUp;
  totalSwipe;
  map = new Map();
  dropdownArray = [];
  dateFilteredAllUser = [];
  finalReportDetails: ReportDetailsFinalized[];
  AllReportDetails: ReportDetails[];
  maxColumn = [];
  selectedDate;
  dtOptions: any = {};
  dates = [];
  RFID_No;
  finalArray = [];
  onSubmitClick = false;
  showButton = false;
  toggleAll = false;
  selectedModel = '';
  selectedShift = '';
  hasReportData = false;
  complianceReport: any;
  complianceReport1: any;
  chartDataURL: any = '';
  chartDataURL1: any = '';
  showDownloadButton = false;
  metadata: any = {};
  devices: HasDevice;
  deviceNames: string[];
  hasPLiteDevice = false;
  SOP: number = 8;
  deviceLocations = [];
  devicesDevice = [];
  selectedLocation: string = '';
  selectedDevice: string = '';

  finalObject: {
    Name: string;
    RFID_No: number;
    Date: string;
    Preset_HHP: number;
    Current_HHP: number;
    Time: string[];
    Status: string[];
    // Completed: number;
    // Incompleted: number;
    Compliance: string;
  };

  headers = ['Name', 'Date', 'Total', 'Compliance'];
  // headers = ['Name', 'Date', 'Total', 'Complete', 'Incomplete', 'Compliance'];

  constructor(
    public reportService: ReportService,
    private authService: AuthService,
    private chRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private commonService: CommonService
  ) {
    this.userData = new SignUp();
    this.devices = new HasDevice();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.resetVariables();
    this.userData = JSON.parse(sessionStorage.getItem(this.authService.USER));
    this.devices = JSON.parse(sessionStorage.getItem(this.authService.DEVICES));
    this.deviceNames = this.devices.deviceNames;
    if (
      this.deviceNames.includes('P Lite') &&
      !this.deviceNames.includes('P Max')
    )
      this.hasPLiteDevice = true;
    this.getTotalSwipeAndDates();
    this.getLocationAndDevices();
    this.getMaxSOP();
    this.chRef.detectChanges();
    this.spinner.hide();
  }

  getMaxSOP() {
    this.reportService.getMaxSOP(this.userData.UId).subscribe(
      (res) => {
        this.SOP = res.maxSOP.toString();
      },
      (err) => {
        this.SOP = 8;
      }
    );
  }

  resetVariables() {
    this.maxColumn = [];
    this.onSubmitClick = false;
    this.selectedDate = '';
    this.selectedModel = '';
    this.selectedShift = '';
    this.showButton = false;
    if (this.finalReportDetails != undefined) {
      while (this.finalReportDetails.length > 0) {
        this.finalReportDetails.pop();
      }
    }
  }

  getTotalSwipeAndDates() {
    this.reportService
      .getTotalSwipe({ SC_Name: 'sc_' + this.userData.UId })
      .subscribe((res) => {
        this.totalSwipe = res.TotalSwipe;
        this.dates = res.Dates.sort();
        this.dates = this.dates.reverse();
      });
  }

  getLocationAndDevices() {
    this.reportService
      .getLocationAndDevices(this.userData.UId)
      .subscribe((res) => {
        this.devicesDevice = res.deviceNames;
        this.deviceLocations = res.location;
      });
  }

  async getReportList() {
    this.spinner.show();
    this.showButton = false;
    if (this.onSubmitClick){
      this.commonService.destroyDatatbles('dataTableAllUser');
      }
    // this.chRef.detectChanges();
    const requestBody = {
      SC_Name: `sc_${this.userData.UId}`,
      Date: this.selectedDate,
      Shift:this.selectedShift,
      Model: this.selectedModel,
      Location: this.selectedLocation,
      DeviceName: this.selectedDevice,
    };
    
    await this.reportService.getReportsforwm4G9k(requestBody).subscribe({
      next: async (res) => {
       // console.log(res);
        this.metadata = res.metadata;
        this.reportService.allReportDetails = [];
        this.reportService.allReportDetails = res.data as ReportDetails[];
        let finalReportDetails = this.finalReportDetails;
        if (finalReportDetails != undefined) {
          while (this.finalReportDetails.length > 0) {
            this.finalReportDetails.pop();
          }
        }
        // this.commonService.destroyDatatbles('dataTableAllUser');

        this.onSubmitClick = true;
        this.countMaxColumn(this.reportService.allReportDetails);
        this.chRef.detectChanges();
        this.headers = ['Name', 'Date', 'Total', 'Compliance (%)'];
        this.headers = this.insertArray(this.headers, 2, this.maxColumn);
        this.chRef.detectChanges();
        this.parseToFinalReport();
        this.fillEmptyArray();

        if (this.finalReportDetails.length) {
          this.hasReportData = true;
          this.showDownloadButton = true;
          this.chRef.detectChanges();
          let graphLabels = this.finalReportDetails.map(({ Name }) => Name);
          let graphData = this.finalReportDetails.map(
            ({ Compliance }) => Compliance
          );
          let graphDataIncomplete = this.finalReportDetails.map(
            ({ Status }) => Status.filter(j=>j==='Incomplete').reduce((total, item) => total + 1, 0));
            let graphDataComplete = this.finalReportDetails.map(
              ({ Status }) => Status.filter(j=>j==='Complete').reduce((total, item) => total + 1, 0));
//console.log(this.finalReportDetails);
//console.log(this.SOP);
          this.metadata.location = `${this.userData.Company_Name}, ${this.userData.Location}`;
          // SOP = 'Minimum 4 times daily Usage';
          this.metadata.SOP = `${this.SOP} times / day`;
//console.log(this.finalReportDetails);
          this.metadata.averageCompliance =
            this.finalReportDetails.length > 1
              ? this.finalReportDetails
                  .map((item) => Number.parseInt(item.Compliance))
                  .reduce((prev, next) => Number(prev) + Number(next))
                  .toFixed()
              : this.finalReportDetails
                  .map((item) => Number.parseInt(item.Compliance))[0]
                  .toFixed();

          this.metadata.averageCompliance = (
            Number(this.metadata.averageCompliance) /
            this.finalReportDetails.length
          ).toFixed();
          if (this.complianceReport && this.complianceReport != null)
            this.complianceReport.destroy();
            if (this.complianceReport1 && this.complianceReport1 != null)
            this.complianceReport1.destroy();
          this.fillComplianceChart(graphLabels, graphData);
          let soplimit=this.maxColumn.length;
          let graphMissing=[];
          graphDataComplete.forEach(function(item,index){
          // console.log(soplimit);
           if(item>=soplimit)
            {
              graphDataComplete[index]=soplimit;
              graphDataIncomplete[index]=0;
            }
            else{
              if((graphDataComplete[index]+graphDataIncomplete[index])>soplimit)
             {
                graphDataIncomplete[index]=soplimit-graphDataComplete[index];
              }
              
            }
          }); 
          this.fillComplianceChart1(graphLabels, graphDataComplete,graphDataIncomplete,soplimit);
          // this.openPdf();
          this.chRef.detectChanges();
        } else {
          this.hasReportData = false;
        }
        this.chRef.detectChanges();
        this.loadDatatbles();
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
      },
    });
  }

  getDocumentDefinition() {
    let columns = [...this.headers],
      rows = [];

    let pushIndex = columns.length - 2;
    columns.splice(pushIndex, 0, 'Usage (Incomplete)');
    columns.splice(pushIndex, 0, 'Usage (Complete)');

    let finalColumns = columns.map((value) => {
      return { text: value, alignment: 'center', style: 'header' };
    });

    let goAssureHeader = [];
    goAssureHeader.push({
      text: [
        'GO',
        {
          text: 'assure',
          italics: true,
        },
        ' - SMART Hand Hygiene Station',
      ],
      colSpan: columns.length,
      style: 'headerBig',
      alignment: 'center',
    });

    let footerData = [];
    footerData.push({
      columns: [
        {
          text: [
            { text: '   ', background: '#ff0000' },
            ' : Compliance (%) less than 25',
          ],
          fontSize: 8,
        },
        {
          text: [
            { text: '   ', background: '#0000ff' },
            ' : Compliance (%) between 25 to 80',
          ],
          fontSize: 8,
        },
        {
          text: [
            { text: '   ', background: '#00ff00' },
            ' : Compliance (%) greater than equal to 80',
          ],
          fontSize: 8,
        },
      ],
      alignment: 'center',
    });

    for (let i = 1; i < columns.length; i++) {
      goAssureHeader.push({ text: '' });
    }

    let width = (100 / finalColumns.length).toFixed(2),
      widths = [];

    for (let i = 0; i < finalColumns.length; i++) {
      widths.push(`${width}%`);
    }

    let numbers = this.headers.slice(2, this.headers.length - 2);

    let singleRow = [],
      singleRecord = null,
      background = '';

    for (let i = 0; i < this.finalReportDetails.length; i++) {
      singleRecord = this.finalReportDetails[i];
      singleRow = [];
      singleRow.push({
        text: singleRecord.Name,
        fontSize: 5,
        alignment: 'center',
      });
      singleRow.push({
        text: singleRecord.Date,
        fontSize: 5,
        alignment: 'center',
      });

      for (let j = 0; j < numbers.length; j++) {
        background =
          singleRecord.Status[j] == 'complete' ||
          singleRecord.Status[j] == 'completed' ||
          singleRecord.Status[j] == 'Complete' ||
          singleRecord.Status[j] == 'Completed'
            ? '#b3ffb3'
            : '#ff8080';

        singleRow.push({
          stack: [
            { text: singleRecord.Time[j], alignment: 'center' },
            { text: singleRecord.Status[j], background, alignment: 'center' },
          ],
          fontSize: 5,
        });
      }

      let completed = singleRecord.Status.filter(
        (ele) =>
          ele == 'complete' ||
          ele == 'completed' ||
          ele == 'Complete' ||
          ele == 'Completed'
      ).length;

      let Incompleted = singleRecord.Status.filter(
        (ele) =>
          ele == 'incomplete' ||
          ele == 'incompleted' ||
          ele == 'Incomplete' ||
          ele == 'Incompleted'
      ).length;

      singleRow.push({ text: completed, fontSize: 5, alignment: 'center' });
      singleRow.push({ text: Incompleted, fontSize: 5, alignment: 'center' });
      singleRow.push({
        text: completed + Incompleted,
        fontSize: 5,
        alignment: 'center',
      });
      singleRow.push({
        text: singleRecord.Compliance,
        fontSize: 5,
        alignment: 'center',
      });

      rows.push(singleRow);
    }

    let {
      SOP,
      averageCompliance,
      consumableToday,
      date,
      location,
      totalCompletedUsageToday,
      totalConsumable,
      totalIncompletedUsageToday,
      totalUsage,
      totalUsageToday,
      totalWaterSaving,
      waterSavingToday,
    } = this.metadata;

    location = `${this.userData.Company_Name}, ${this.userData.Location}`;
    // SOP = 'Minimum 4 times daily Usage';
    SOP = `${this.SOP} times / day`;

    averageCompliance = this.finalReportDetails
      .map((item) => Number.parseInt(item.Compliance))
      .reduce((prev, next) => Number(prev) + Number(next))
      .toFixed();

    averageCompliance = (
      Number(averageCompliance) / this.finalReportDetails.length
    ).toFixed();

    // let totalColumn = 22;
    let divideBy = 4;
    let extraCount = columns.length % 4;
    let columnsLength = columns.length;
    let baseColspan = Number.parseInt(
      (columnsLength / divideBy).toString(),
      10
    );
    let colspanData = [];

    function fillColspanData(extraCount) {
      switch (extraCount) {
        case 0:
          colspanData = Array(4).fill(baseColspan);
          break;
        case 1:
          colspanData = [...Array(3).fill(baseColspan), baseColspan + 1];
          break;
        case 2:
          colspanData = [
            baseColspan + 1,
            ...Array(2).fill(baseColspan),
            baseColspan + 1,
          ];
          break;
        case 3:
          colspanData = [
            ...Array(2).fill(baseColspan + 1),
            baseColspan,
            baseColspan + 1,
          ];
          break;
        default:
          colspanData = Array(4).fill(baseColspan);
      }
    }

    fillColspanData(extraCount);
    colspanData;

    let firstRow = [],
      secondRow = [],
      thirdRow = [];

    let dataaa = [1];
    for (let i = 0; i < colspanData.length - 1; i++) {
      const lastIndex = dataaa.length - 1;
      dataaa.push(dataaa[lastIndex] + colspanData[i]);
    }

    let firstRowData = [
      {
        text: 'Total usage:',
        value: totalUsage,
      },
      {
        text: 'Total water saving:',
        value: this.hasPLiteDevice
          ? `Only applicable on P MAX`
          : `${totalWaterSaving} L`,
      },
      {
        text: 'Total consumable usage:',
        value: `${totalConsumable} L`,
      },
      {
        text: 'Average compliance (%):',
        value: averageCompliance,
      },
    ];

    let secondRowData = [
      {
        text: 'Company/Location:',
        value: location,
      },
      {
        text: 'Water saving today:',
        value: this.hasPLiteDevice
          ? `Only applicable on P MAX`
          : `${waterSavingToday} L`,
      },
      {
        text: 'Consumable usage today:',
        value: `${consumableToday} L`,
      },
      {
        text: 'Date:',
        value: date,
      },
    ];

    let thirdRowData = [
      {
        text: 'Total usage today:',
        value: totalUsageToday,
      },
      {
        text: 'Total complete usage today:',
        value: totalCompletedUsageToday,
      },
      {
        text: 'Total incomplete usage today:',
        value: totalIncompletedUsageToday,
      },
      {
        text: 'SOP definition:',
        value: SOP,
      },
    ];

    let count = 0;
    for (let i = 1; i <= columns.length; i++) {
      if (dataaa.includes(i)) {
        let border =
          count === 0
            ? [true, false, false, false]
            : count === 3
            ? [false, false, true, false]
            : [false, false, false, false];
        firstRow.push({
          text: `${firstRowData[count].text} ${firstRowData[count].value}`,
          colSpan: colspanData[count],
          alignment: 'center',
          fontSize: 8,
          border,
        });
        count++;
      } else {
        firstRow.push({ text: '' });
      }
    }

    count = 0;
    for (let i = 1; i <= columns.length; i++) {
      if (dataaa.includes(i)) {
        let border =
          count === 0
            ? [true, false, false, false]
            : count === 3
            ? [false, false, true, false]
            : [false, false, false, false];
        secondRow.push({
          text: `${secondRowData[count].text} ${secondRowData[count].value}`,
          colSpan: colspanData[count],
          alignment: 'center',
          fontSize: 8,
          border,
        });
        count++;
      } else {
        secondRow.push({ text: '' });
      }
    }

    count = 0;

    for (let i = 1; i <= columns.length; i++) {
      if (dataaa.includes(i)) {
        let border =
          count === 0
            ? [true, false, false, false]
            : count === 3
            ? [false, false, true, false]
            : [false, false, false, false];
        thirdRow.push({
          text: `${thirdRowData[count].text} ${thirdRowData[count].value}`,
          colSpan: colspanData[count],
          alignment: 'center',
          fontSize: 8,
          border,
        });
        count++;
      } else {
        thirdRow.push({ text: '' });
      }
    }

    return {
      info: {
        title: 'Micro GO - P-Report',
        author: 'Micro GO LLP',
        subject: 'P-Report',
      },
      pageOrientation: 'landscape',
      content: [
        {
          style: 'headerTable',
          table: {
            widths: ['35%', '35%', '34%', '0%', '0%', '0%'],
            body: [
              [
                {},
                {
                  text: 'MicroGO | P MAX / P Lite - Report',
                  style: 'headerBig',
                  alignment: 'center',
                  margin: [0, 25, 0, 0],
                },
                {},
              ],
            ],
          },
          layout: 'noBorders',
        },
        { text: '', margin: [0, 20, 0, 0] },
        {
          style: 'tableExample',
          table: {
            widths: widths,
            body: [
              goAssureHeader,
              firstRow,
              secondRow,
              thirdRow,
              finalColumns,
              ...rows,
            ],
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return rowIndex >= 2 && rowIndex % 2 === 0 ? '#f0eded' : null;
            },
          },
        },
        {
          table: {
            widths: ['*'],
            body: [
              [
                {
                  image: 'chart',
                  fit: [500, 500],
                  alignment: 'center',
                },
                
              ],
            ],
          },
        },
        {
          table: {
            widths: ['*'],
            body: [
              [
                
                {
                  image: 'chart1',
                  fit: [500, 500],
                  alignment: 'center',
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: ['*'],
            body: [footerData],
          },
        },
        {
          text: '**only Set SOP data will be visible , above SOP usage count can be checked in the total count . For details check "View all reports" in the website.',
          fontSize: 8,
          alignment: 'center',
          margin: [0, 0, 0, 3],
        },
        {
          text: 'Please note: Overall Compliance % on the dashboard  is calculated as complete vs Incomplete cycle whereas on the daily report, the compliance % is reported as defined by the SOP required by you',
          fontSize: 8,
          alignment: 'center',
        },
      ],
      images: {
        chart: this.chartDataURL,
      
        chart1: this.chartDataURL1,
      },
      styles: {
        headerBig: {
          fontSize: 14,
          bold: true,
          alignment: 'justify',
          margin: [0, 5, 0, 5],
        },
        header: {
          fontSize: 6,
          bold: true,
          alignment: 'justify',
          margin: [0, 5, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
          alignment: 'justify',
        },
        headerTable: {
          alignment: 'justify',
        },
      },
    };
  }

  async convertHtmlToImageDataURL() {
    this.chartDataURL = this.complianceReport.toBase64Image();
    this.chartDataURL1 = this.complianceReport1.toBase64Image();
  }
  async convertHtmlToImageDataURL1() {
    
  }

  async openPdf() {
    // const documentDefinition = this.getDocumentDefinition();
    // let dom = JSON.stringify(documentDefinition);
    // dom = JSON.parse(dom);

    await this.convertHtmlToImageDataURL();
    pdfMake.createPdf(this.getDocumentDefinition()).open();
  }

  async downloadReport() {
    await this.convertHtmlToImageDataURL();
    pdfMake
      .createPdf(this.getDocumentDefinition())
      .download(`MicroGo - P-Report ${this.selectedDate}`);
  }

  changeToReport() {
    this.spinner.show();
    this.toggleAll = !this.toggleAll;
    if (this.toggleAll) {
      this.getAllReports();
      this.spinner.hide();
    } else {
      this.resetVariables();
      this.spinner.hide();
    }
  }

  poolColors(graphData: []) {
    let colours = [];
    let colors = {
      blue: 'rgba(0,0,255,1)',
      green: 'rgba(0, 255, 0,1)',
      red: 'rgba(255,0,0,1)',
    };
    for (let i = 0; i < graphData.length; i++) {
      if (Number(graphData[i]) >= 80) {
        colours.push(colors.green);
      } else if (Number(graphData[i]) > 25) {
        colours.push(colors.blue);
      } else {
        colours.push(colors.red);
      }
    }
    return colours;
  }

  fillComplianceChart(graphLabel, graphData) {
    
    this.complianceReport = new Chart('complianceReport', {
      type: 'bar',
      data: {
        labels: graphLabel,
        datasets: [
          {
            // label: 'Compliance (%)',
            // backgroundColor: 'rgba(0,0,255,0.5)',
            backgroundColor: this.poolColors(graphData),
            barPercentage: 0.5,
            barThickness: 30,
            maxBarThickness: 50,
            minBarLength: 2,
            hoverBackgroundColor: 'rgba(0,0,255,0.7)',
            borderColor: 'rgba(0,0,255,1)',
            data: graphData,
          },
        ],
      },
      options: {
        devicePixelRatio: 1.5,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 25,
            top: 25,
            bottom: 0,
          },
        },
        scales: {
          xAxes: {
            title: {
              text: 'Personnel Names',
              display: true,
            },
            time: {
              unit: 'day',
            },
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              // maxTicksLimit: 8,
            },
          },
          yAxes: {
            title: {
              text: 'Hand Hygiene Compliance %',
              display: true,
            },
            ticks: {
              minRotation: 0,
              maxRotation: 100,
              maxTicksLimit: 6,
              padding: 10,
              // Include a dollar sign in the ticks
              // callback: function(value, index, values) {
              //     return '$' + number_format(value);
              // }
            },
            grid: {
              color: 'rgb(234, 236, 244)',
              drawBorder: false,
              borderDash: [2],
            },
          },
        },
        plugins: {
          legend: {
            display: false,
            position: 'top',
            title: {
              text: 'Compliance(%)',
              color: '#000000',
              display: true,
            },
          },
          tooltip: {
            titleMarginBottom: 10,
            titleColor: '#6e707e',
            titleFont: {
              size: 14,
            },
            backgroundColor: 'rgb(255,255,255)',
            bodyColor: '#858796',
            borderColor: '#dddfeb',
            borderWidth: 1,
            padding: 15,
            displayColors: false,
            caretPadding: 10,
            /* callbacks: {
                  label: function (context) {
                    return `${Number(context.parsed.y)}`;
                  },
                }, */
          },
        },
      },
    });
    this.spinner.hide();
  }

  async getAllReports() {
    await this.reportService
      .getAllReports({
        SC_Name: 'sc_' + this.userData.UId,
      })
      .subscribe(async (data) => {
        this.AllReportDetails = data.data as ReportDetails[];
        await this.chRef.detectChanges();
        await this.loadDatatbles();
      });
  }

  loadDatatbles() {
    this.commonService.loadReportDatatables('dataTableAllUser');
  }

  parseToFinalReport() {
    let RFID_No = null,
      i = -1;
    this.finalArray.length = 0;
    for (var data of this.reportService.allReportDetails) {
      /*  let completed = 0,
        incompleted = 0; */
      if (RFID_No == data.RFID_No) {
        this.finalArray[i].Time.push(data.Time);
        this.finalArray[i].Status.push(data.Status);

        /* if (
          ['complete', 'Complete', 'completed', 'Completed'].includes(
            data.Status
          )
        ) {
          completed = +1;
        } else if (
          ['incomplete', 'Incomplete', 'incompleted', 'Incompleted'].includes(
            data.Status
          )
        ) {
          incompleted = +1;
        } */
      } else {
        i += 1;
        RFID_No = data.RFID_No;
        this.finalObject = {
          Name: data.Name,
          RFID_No: data.RFID_No,
          Date: data.Date,
          Preset_HHP: data.Preset_HHP,
          Current_HHP: data.Current_HHP,
          Time: [data.Time],
          Status: [data.Status],
          // Completed: completed,
          // Incompleted: incompleted,
          Compliance: (
            (Number(data.Current_HHP) * 100) /
            Number(data.Preset_HHP)
          ).toFixed(2),
        };
        this.finalArray.push(this.finalObject);
      }
    }
    this.finalReportDetails = [...this.finalArray];
  }

  fillEmptyArray() {
    let max = this.maxColumn.length,
      current;
    for (let data of this.finalReportDetails) {
      data.Current_HHP = data.Time.length;
      current = 0;
      data.Status.forEach((status) => {
        if (status.toLowerCase() == ('complete' || 'completed')) {
          current++;
        }
      });
      data.Compliance = (
        (Number(current) * 100) /
        Number(data.Preset_HHP)
      ).toFixed(2);
      data.Compliance =
        Number(data.Compliance) > 100 ? `100.00` : data.Compliance;
      for (let i = data.Time.length; i < max; i++) {
        data.Time.push('0');
        //Do Not Uncomment below line
        //data.Status.push('0');
      }
    }
  }

  fillRows() {
    const data = this.reportService.allReportDetails;
    this.RFID_No = data[0].RFID_No;

    var rows = [];
    for (let i in data) {
      rows.push({
        Name: data[i].Name,
        Date: data[i].Date,
        Total: data[i].Preset_HHP,
        Compliance: null,
      });
      this.insertArray(rows[i], 2, rows);
      if (this.RFID_No == data[i].RFID_No) {
        var result = rows.map(function (el) {
          var o = Object.assign({}, el);
          o.i = true;
          return o;
        });
      }
    }
  }

  countMaxColumn(arr) {
    const max = Math.max(...arr.map((o: any) => o.Preset_HHP), 0);
    this.maxColumn.length = 0;
    for (let i = 1; i <= max; i++) {
      this.maxColumn.push(i.toString());
    }
  }

  insertArray(arr, index, items) {
    return [...arr.slice(0, index), ...items, ...arr.slice(index)];
  }

  /* fillDropdown() {
    for (let data of this.reportService.allReportDetails) {
      if (!this.map.has(data.Date)) {
        this.map.set(data.Date, true);
        this.dropdownArray.push({
          Date: data.Date,
        });
      }
    }
  } */

  onOptionsSelected(event) {
    if (event.target.value != 0) {
      this.selectedDate = event.target.value;
      this.showButton = this.selectedModel && this.selectedDate ? true : false;
    }
  }

  onModelSelected(event) {
    if (event.target.value != 'All') {
      this.selectedModel = event.target.value;
    } else {
      this.selectedModel = 'All';
    }
    this.showButton = this.selectedModel && this.selectedDate ? true : false;
  }

  onShiftSelected(event) {
    if (event.target.value != 'All') {
      this.selectedShift = event.target.value;
    } else {
      this.selectedShift = 'All';
    }
    this.showButton = this.selectedModel && this.selectedDate ? true : false;
  }

  onLocationSelected(event) {
    if (event.target.value != 'none') {
      this.selectedLocation = event.target.value;
    } else {
      this.selectedLocation = '';
    }
    this.showButton = this.selectedModel && this.selectedDate ? true : false;
  }

  onDeviceSelected(event) {
    if (event.target.value != 'none') {
      this.selectedDevice = event.target.value;
    } else {
      this.selectedDevice = '';
    }
    this.showButton = this.selectedModel && this.selectedDate ? true : false;
  }






  fillComplianceChart1(graphLabel, graphDataComplete, graphIncomplete,limit) {
   // let cnt=this.maxColumn.length;
    let gd1=[];
    let gd2=[];
    let gd3=[];
    graphDataComplete.forEach(function(part, index) {
      gd1[index] =graphDataComplete[index];
      gd2[index] =graphIncomplete[index];
     gd3[index] =limit-(graphDataComplete[index]+graphIncomplete[index]);
    });
    //console.log(cnt);
    //console.log(gd1);
    //console.log(gd2);
    //console.log(gd3);
    
    this.complianceReport1 = new Chart('complianceReport1', {
      type: 'bar',
      data: {
        labels: graphLabel,        
        datasets: [
          {
            // label: 'Compliance (%)',
            // backgroundColor: 'rgba(0,0,255,0.5)',
            backgroundColor: 'rgba(0, 255, 0,1)',
            barPercentage: 0.5,
            barThickness: 30,
            maxBarThickness: 50,
            minBarLength: 2,
            hoverBackgroundColor: 'rgba(0,0,255,0.7)',
            borderColor: 'rgba(0,0,255,1)',
            data: gd1,
          },
          
          {
            // label: 'Compliance (%)',
            // backgroundColor: 'rgba(0,0,255,0.5)',
            backgroundColor: 'rgba(255,0,0,1)',
            barPercentage: 0.5,
            barThickness: 30,
            maxBarThickness: 50,
            minBarLength: 0,
            hoverBackgroundColor: 'rgba(0,0,255,0.7)',
            borderColor: 'rgba(255,0,0,1)',
            data: gd2,
          },
          {
            // label: 'Compliance (%)',
            // backgroundColor: 'rgba(0,0,255,0.5)',
            backgroundColor: 'rgba(255,255,0,1)',
            barPercentage: 0.5,
            barThickness: 30,
            maxBarThickness: 50,
            minBarLength: 0,
            hoverBackgroundColor: 'rgba(0,0,255,0.7)',
            borderColor: 'rgba(255,255,0,1)',
            data: gd3,
          },
        ],
      },
      
      options: {
        devicePixelRatio: 1.5,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 25,
            top: 25,
            bottom: 0,
          },
        },
        scales: {
          xAxes: {
            stacked: true,
            title: {
              text: 'Personnel Names',
              display: true,
            },
            time: {
              unit: 'day',
            },
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              // maxTicksLimit: 8,
            },
          },
          yAxes: {
            stacked: true,
            title: {
              text: 'Hand Hygiene Compliance %',
              display: true,
            },
            max:parseInt(limit),
            
            ticks: {
              precision:0,
              minRotation: 0,
              maxRotation: 100,
              maxTicksLimit: 6,
              padding: 1
              // Include a dollar sign in the ticks
              // callback: function(value, index, values) {
              //     return '$' + number_format(value);
              // }
            },
            grid: {
              color: 'rgb(234, 236, 244)',
              drawBorder: false,
              borderDash: [2],
            },
          },
        },
        plugins: {
          legend: {
            display: false,
            position: 'top',
            title: {
              text: 'Compliance(%)',
              color: '#000000',
              display: true,
            },            
          },
          tooltip: {
            titleMarginBottom: 10,
            titleColor: '#6e707e',
            titleAlign: 'center',
            titleFont: {
              size: 14,
            },
            backgroundColor: 'rgb(255,255,255)',
            bodyColor: '#858796',
            borderColor: '#dddfeb',
            borderWidth: 0,
            padding: 15,
            displayColors: false,
            caretPadding: 10,
            callbacks: {
                  label: function (context) {
                    let val=context.raw;
                    if(JSON.stringify(gd1)==JSON.stringify(context.dataset.data))
                    {
                      
                      return `Completed: `+val;
                    }
                    else if(JSON.stringify(gd2)==JSON.stringify(context.dataset.data)){
                      return `Incomplete: `+val;
                    }
                    else{
                      return `Missed: `+val;
                    }
                  },
                }, 
          },
        },
      },
    });
    this.spinner.hide();
  }

}
