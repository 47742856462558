export class UserReg {
  public SC_Name: string;
  public Id: number;
  public UId: string;
  public RFID_No: number;
  public Name: string;
  public Dept: string;
  public Mobile: number;
  public Email: string;
  public Preset_HHP: number;
  public Date_Added:string;
  public Time_Added:string;
}
