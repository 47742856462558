import { OReportModel } from './../models/O-ReportModel';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '../Common/config';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ODeviceService {
  constructor(private http: HttpClient) {}
  allReportDetails: OReportModel[];

  addCardDetail(data: any) {
    return firstValueFrom(
      this.http.post<any>(`${config.apiUrl}/OaddCard`, data),
      { defaultValue: false }
    );
  }

  getAllCards(UId: any) {
    return firstValueFrom(
      this.http.get<any>(`${config.apiUrl}/getAllCard?UId=${UId}`),
      { defaultValue: false }
    );
  }

  getDashboardData(UId: any) {
    return firstValueFrom(
      this.http.get<any>(`${config.apiUrl}/o-dashboard?UId=${UId}`),
      { defaultValue: false }
    );
  }

  getLitePlusDashboardData(UId: any) {
    return firstValueFrom(
      this.http.get<any>(`${config.apiUrl}/lite-plus-dashboard?UId=${UId}`),
      { defaultValue: false }
    );
  }

  getOpportunityUsage(UId: any) {
    return firstValueFrom(
      this.http.get<any>(`${config.apiUrl}/o-dailyreport?UId=${UId}`),
      { defaultValue: false }
    );
  }

  getLitePlusOpportunityUsage(UId: any) {
    return firstValueFrom(
      this.http.get<any>(`${config.apiUrl}/lite-plus-dailyreport?UId=${UId}`),
      { defaultValue: false }
    );
  }

  getDeviceInformation(UId: any) {
    return firstValueFrom(
      this.http.get<any>(`${config.apiUrl}/o-deviceInformation?UId=${UId}`),
      { defaultValue: false }
    );
  }

  getLitePlusDeviceInformation(UId: any) {
    return firstValueFrom(
      this.http.get<any>(
        `${config.apiUrl}/lite-plus-deviceInformation?UId=${UId}`
      ),
      { defaultValue: false }
    );
  }

  getReportByDate(
    UId: string,
    startDate: string,
    endDate: string,
    isLitePlus: boolean
  ) {
    return firstValueFrom(
      this.http.get<any>(
        `${config.apiUrl}/o-report?UId=${UId}&startDate=${startDate}&endDate=${endDate}&isLitePlus=${isLitePlus}`
      ),
      { defaultValue: false }
    );
  }

  getTotalDates(data: any) {
    return this.http.post<any>(`${config.apiUrl}/o-report/totalDates`, data);
  }

  getLocationAndDevices(UId: any) {
    return this.http.get<any>(
      `${config.apiUrl}/o-report/locationAndDevice?UId=${UId}`
    );
  }

  getReportsByFilter(requestBody) {
    return this.http.post<any>(
      `${config.apiUrl}/o-reports-by-filter`,
      requestBody
    );
  }

  getAllOpportunityReport(UId: string, isLitePlus: boolean) {
    return firstValueFrom(
      this.http.get<any>(
        `${config.apiUrl}/o-all-opportunity?UId=${UId}&isLitePlus=${isLitePlus}`
      ),
      { defaultValue: false }
    );
  }

  getAllUsageReport(UId: string, isLitePlus: boolean) {
    return firstValueFrom(
      this.http.get<any>(
        `${config.apiUrl}/o-all-usage?UId=${UId}&isLitePlus=${isLitePlus}`
      ),
      { defaultValue: false }
    );
  }

  getTotalOpportunity(UId: string, isLitePlus: boolean) {
    return firstValueFrom(
      this.http.get<any>(
        `${config.apiUrl}/total-opportunity?UId=${UId}&isLitePlus=${isLitePlus}`
      ),
      { defaultValue: false }
    );
  }

  editCardDetail(data: any) {
    return firstValueFrom(
      this.http.post<any>(`${config.apiUrl}/update-OCard`, data),
      { defaultValue: false }
    );
  }

  deleteCardDetail(data: any) {
    return firstValueFrom(
      this.http.post<any>(`${config.apiUrl}/delete-OCard`, data),
      { defaultValue: false }
    );
  }
}
