import { AttendanceService } from './../../services/attendance.service';
import { CreateAccountService } from './../../services/create-account.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SignUp } from 'src/app/models/sigup';
import { UserReg } from 'src/app/models/userReg';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AllUser } from 'src/app/models/allUsers';
import { CommonService } from 'src/app/services/common.service';
import jsPDF from 'jspdf';
import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import html2canvas from 'html2canvas';

declare var $: any;
//import $ from 'jquery';
declare var jQuery: any;

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css'],
})
export class AttendanceComponent implements OnInit {
  @ViewChild('htmlData', { read: ElementRef }) htmlData: ElementRef;

  userData: SignUp;
  userReg: UserReg;
  userEdit: UserReg;
  selectedUser = [];
  dateFilteredSelectedUser = [];
  allUser = [];
  dateFilteredAllUser = [];
  now: string;
  toggleERP: boolean;
  toggleSpecific: boolean;
  sort: boolean = false;
  selectedRfid_No: number;
  map = new Map();
  dropdownArray = [];
  particularDate: any;
  startDate: any;
  endDate: any;
  viewByRange: boolean = false;
  viewBySingleDate: boolean = false;
  toggleDateFilter: boolean = false;
  isCheckValid: boolean;
  addUserToggle: boolean;
  editUserToggle: boolean = false;

  constructor(
    private createAccountService: CreateAccountService,
    private authService: AuthService,
    private attendanceService: AttendanceService,
    private commonService: CommonService,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private chRef: ChangeDetectorRef,
    private router: Router
  ) {
    this.userData = new SignUp();
    this.userReg = new UserReg();
    this.userEdit = new UserReg();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.toggleERP = true;
    this.toggleSpecific = false;
    this.toggleDateFilter = false;

    this.userData = JSON.parse(sessionStorage.getItem(this.authService.USER));
    this.refreshUserList();
    this.spinner.hide();
  }

  addUser() {
    this.userReg.SC_Name = 'sc_' + this.userData.UId;
    this.userReg.UId = this.userData.UId;
    this.userReg.Date_Added = new Date(Date.now())
      .toLocaleDateString('en-GB')
      .split(',')[0];
    this.userReg.Time_Added = new Date()
      .toLocaleTimeString('en-US', { hour12: false })
      .slice(0, 8);

    const body = {
      RFID_No: this.userReg.RFID_No,
      UId: this.userData.UId,
    };

    this.attendanceService.addRFID(body).subscribe((res) => {
      if (res['Success'] == true) {
        this.attendanceService.addUser(this.userReg).subscribe((res) => {
          if (res['Success'] == true) {
            this.commonService.UpdateTotalEmployee(body).subscribe((res) => {
              if (res['Success'] == true) {
                this.clearData();
                this.toastrService.success(
                  'Member Added Successfully!',
                  'Added!'
                );
              } else {
                this.clearData();
                this.toastrService.warning('Something Went Wrong!', 'Oops!');
              }
            });
          } else {
            this.clearData();
            this.toastrService.warning('Something Went Wrong!', 'Oops!');
          }
        });
      } else {
        this.toastrService.warning('RFID Not Valid!', 'Oops!');
      }
    });
    this.commonService.destroyDatatbles('dataTableHover');
    this.refreshUserList();
  }

  clearData() {
    this.userReg.Name = '';
    this.userReg.Dept = '';
    this.userReg.RFID_No = null;
    this.userReg.Mobile = null;
    this.userReg.Email = '';
    this.userReg.Preset_HHP = null;
    this.editUserToggle = false;
    this.addUserToggle = true;
  }

  changeToggleERP() {
    this.toggleERP = !this.toggleERP;
    this.chRef.detectChanges();
    if (this.toggleERP) {
      this.commonService.loadReportDatatables('dataTableAllUser');
    } else {
      this.commonService.loadReportDatatables('dataTableHover');
    }
  }

  refreshUserList() {
    this.attendanceService
      .getAllUser({ SC_Name: 'sc_' + this.userData.UId })
      .subscribe((res) => {
        this.attendanceService.allUserDetails = res as AllUser[];
        this.allUser = null;
        this.allUser = this.attendanceService.allUserDetails;
        this.fillDropdown();
        this.totalHours();
        this.chRef.detectChanges();
        this.commonService.loadReportDatatables('dataTableAllUser');
      });

    this.attendanceService
      .getUserDetails({ SC_Name: 'sc_' + this.userData.UId })
      .subscribe((res) => {
        this.attendanceService.allmembers = res as UserReg[];
        this.chRef.detectChanges();
        this.commonService.loadReportDatatables('dataTableHover');
      });
  }

  onOptionsSelected(event) {
    this.commonService.destroyDatatbles('dataTableAllUser');
    this.commonService.destroyDatatbles('dataTableAllUserDate');
    this.commonService.destroyDatatbles('dataTableSpecificUser');
    this.commonService.destroyDatatbles('dataTableSpecificUserDate');
    if (event.target.value != 0) {
      this.selectedRfid_No = event.target.value;
      this.selectedUser = null;
      this.selectedUser = this.attendanceService.allUserDetails.filter(
        (data) => data.RFID_No == this.selectedRfid_No
      );
      this.toggleSpecific = true;
      this.chRef.detectChanges();
      if (this.toggleDateFilter) {
        this.commonService.loadReportDatatables('dataTableSpecificUserDate');
      } else {
        this.commonService.loadReportDatatables('dataTableSpecificUser');
      }
    } else {
      this.toggleSpecific = false;
      this.allUser = null;
      this.allUser = this.attendanceService.allUserDetails;
      this.chRef.detectChanges();
      if (this.toggleDateFilter) {
        this.commonService.loadReportDatatables('dataTableAllUserDate');
      } else {
        this.commonService.loadReportDatatables('dataTableAllUser');
      }
    }
  }

  fillDropdown() {
    for (let data of this.attendanceService.allUserDetails) {
      if (!this.map.has(data.RFID_No)) {
        this.map.set(data.RFID_No, true);
        this.dropdownArray.push({
          Name: data.Name,
          RFID_No: data.RFID_No,
        });
      }
    }
  }

  totalHours() {
    for (let i = 0; i < this.attendanceService.allUserDetails.length; i++) {
      const date = this.attendanceService.allUserDetails[i].Date;
      const startTimeString =
        this.attendanceService.allUserDetails[i].First_In_time;
      const endTimeString =
        this.attendanceService.allUserDetails[i].Last_Out_Time;

      const startTime = new Date(`${date} ${startTimeString}`).getTime();

      const endTime = new Date(`${date} ${endTimeString}`).getTime();

      const diff = ((endTime - startTime) / (1000 * 60 * 60)).toFixed(2);

      this.attendanceService.allUserDetails[i].TotalHours = Number(diff);
    }
  }

  onViewBySelected(event) {
    this.commonService.destroyDatatbles('dataTableAllUserDate');
    this.commonService.destroyDatatbles('dataTableSpecificUserDate');
    this.commonService.destroyDatatbles('dataTableSpecificUser');
    this.commonService.destroyDatatbles('dataTableAllUser');
    if (event.target.value == 'None') {
      this.toggleDateFilter = false;
      this.viewByRange = false;
      this.viewBySingleDate = false;
      this.chRef.detectChanges();
      if (this.toggleSpecific) {
        this.commonService.loadReportDatatables('dataTableSpecificUser');
      } else {
        this.commonService.loadReportDatatables('dataTableAllUser');
      }
    } else if (event.target.value == 'Range') {
      this.toggleDateFilter = true;
      this.viewByRange = true;
      this.viewBySingleDate = false;
      this.chRef.detectChanges();
    } else if (event.target.value == 'Single') {
      this.toggleDateFilter = true;
      this.viewByRange = false;
      this.viewBySingleDate = true;
      this.chRef.detectChanges();
    } else {
      this.toggleDateFilter = false;
      this.viewByRange = false;
      this.viewBySingleDate = false;
      this.chRef.detectChanges();
    }
  }

  onViewData() {
    this.commonService.destroyDatatbles('dataTableAllUserDate');
    this.commonService.destroyDatatbles('dataTableSpecificUserDate');
    this.commonService.destroyDatatbles('dataTableSpecificUser');
    this.commonService.destroyDatatbles('dataTableAllUser');
    if (this.toggleDateFilter == true) {
      if (this.viewByRange == false && this.viewBySingleDate == true) {
        if (this.particularDate == undefined) {
          this.toastrService.warning('Please Select Date!', 'Required!');
          return;
        }
        this.spinner.show();
        let singleDate = new Date(this.particularDate)
          .toISOString()
          .split('T')[0];
        if (this.toggleSpecific == true) {
          this.dateFilteredSelectedUser = null;
          this.dateFilteredSelectedUser = this.selectedUser.filter(
            (item) => item.Date == singleDate
          );
          this.chRef.detectChanges();
          this.commonService.loadReportDatatables('dataTableSpecificUserDate');
        } else {
          this.dateFilteredAllUser = null;
          this.dateFilteredAllUser = this.allUser.filter(
            (item) => item.Date == singleDate
          );
          this.chRef.detectChanges();
          this.commonService.loadReportDatatables('dataTableAllUserDate');
        }
        this.spinner.hide();
      } else {
        if (this.startDate == undefined || this.endDate == undefined) {
          this.toastrService.warning('Please Select Date!', 'Required!');
          return;
        }
        this.spinner.show();
        let startDate = new Date(this.startDate).toISOString().split('T')[0];
        let endDate = new Date(this.endDate).toISOString().split('T')[0];

        if (this.toggleSpecific == true) {
          this.dateFilteredSelectedUser = null;
          this.dateFilteredSelectedUser = this.selectedUser.filter(
            (item) => item.Date >= startDate && item.Date <= endDate
          );
          this.chRef.detectChanges();
          this.commonService.loadReportDatatables('dataTableSpecificUserDate');
        } else {
          this.dateFilteredAllUser = null;
          this.dateFilteredAllUser = this.allUser.filter(
            (item) => item.Date >= startDate && item.Date <= endDate
          );
          this.chRef.detectChanges();
          this.commonService.loadReportDatatables('dataTableAllUserDate');
        }
        this.spinner.hide();
      }
    } else {
      this.toggleSpecific = false;
      this.allUser = null;
      this.allUser = this.attendanceService.allUserDetails;
      this.chRef.detectChanges();
      this.commonService.loadReportDatatables('dataTableAllUser');
      this.spinner.hide();
    }
  }

  downloadPDF() {
    this.spinner.show();
    //let data = document.getElementById(div_id);
    let data = this.htmlData.nativeElement;
    html2canvas(data).then((canvas) => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png');
      const position = 0;
      // let pdf = new jsPDF('l', 'cm', 'a4'); //Generates PDF in landscape mode
      let pdf = new jsPDF('p', 'mm', 'a4'); // Generates PDF in portrait mode
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);

      this.spinner.hide();
      let date = new Date(Date.now()).toLocaleDateString('en-GB').split(',')[0];
      let filename = date + '-Report.pdf';
      pdf.save(filename);
    });
    this.spinner.hide();
  }

  onDelete(Id: number) {
    //this.toastr.warning()

    Swal.fire({
      title: 'Are you sure?',
      text: "Every Record Of this user will be deleted And You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.attendanceService
          .deleteUser({ SC_Name: 'sc_' + this.userData.UId, Id: Id })
          .subscribe((res) => {
            this.commonService.destroyDatatbles('dataTableHover');
            this.refreshUserList();
            Swal.fire('Deleted!', 'Your record has been deleted.', 'success');
          });
      }
    });
  }

  onEdit(data: UserReg) {
    this.clearData();
    this.userEdit = data;

    this.editUserToggle = true;
    this.addUserToggle = false;
  }

  editUserDetails() {
    this.userEdit.SC_Name = 'sc_' + this.userData.UId;
    this.userEdit.UId = this.userData.UId;

    this.attendanceService.updateUser(this.userEdit).subscribe((res) => {
      if (res['Success'] == true) {
        this.commonService.destroyDatatbles('dataTableHover');
        this.refreshUserList();
        Swal.fire('Updated!', 'Your record has been updated.', 'success');
      } else {
        Swal.fire('error!', 'Something Went Wrong!', 'warning');
      }
    });
    this.clearData();
  }

  onDownloadSelected(event) {
    let id;

    if (this.toggleERP) {
      if (this.toggleSpecific) {
        if (this.toggleDateFilter) {
          id = 'dataTableSpecificUserDate';
        } else {
          id = 'dataTableSpecificUser';
        }
      } else {
        if (this.toggleDateFilter) {
          id = 'dataTableAllUserDate';
        } else {
          id = 'dataTableAllUser';
        }
      }
    } else {
      id = 'dataTableHover';
    }

    let date = new Date(Date.now()).toLocaleDateString('en-GB').split(',')[0];

    if (event.target.value == 'PDF') {
      $('#' + id).tableExport({
        type: 'pdf',
        escape: 'false',
        fileName: 'MicroGo_Report ' + date,
      });
    } else if (event.target.value == 'CSV') {
      $('#' + id).tableExport({
        type: 'csv',
        escape: 'false',
        fileName: 'MicroGo_Report ' + date,
      });
    } else if (event.target.value == 'Excel') {
      $('#' + id).tableExport({
        type: 'excel',
        escape: 'false',
        fileName: this.toggleERP
          ? 'MicroGo_Report ' + date
          : 'Registered Users List',
      });
    } else {
      return;
    }
  }
}
