import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SignUp } from '../models/sigup';
import Swal from 'sweetalert2';
import { CreateAccountService } from '../services/create-account.service';
import { UserProfileService } from '../services/user-profile.service';
import { AuthService } from '../auth/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  userData: SignUp;
  fileSelected: boolean;
  imgsrc: string;

  constructor(
    private createAccountService: CreateAccountService,
    private userProfileService: UserProfileService,
    private authService: AuthService,
    private chRef: ChangeDetectorRef,
    private router: Router
  ) {
    this.userData = new SignUp();
  }

  ngOnInit(): void {
    this.userData = JSON.parse(sessionStorage.getItem(this.authService.USER));
    this.getProfile();
  }

  base64textString = [];

  onUploadChange(evt: any) {
    console.log('event entered');
    const file = evt.target.files[0];
    const filename = evt.target.files[0].name;

    if (file) {
      const reader = new FileReader();

      this.base64textString.splice(0, this.base64textString.length);
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }

    this.fileSelected = true;
    console.log(this.fileSelected);
    console.log(this.base64textString);
  }

  handleReaderLoaded(e) {
    while (this.base64textString.length) {
      this.base64textString.pop();
    }
    this.base64textString.push(
      'data:image/png;base64,' + btoa(e.target.result)
    );
  }

  getProfile() {
    const body = {
      UId: this.userData.UId,
    };

    this.userProfileService.getProfileByUId(body).subscribe((res) => {
      if (res.length > 0) {
        this.imgsrc = res[0]['Image'];
      } else {
        this.imgsrc = 'https://www.w3schools.com/howto/img_avatar2.png';
      }
    });
    this.chRef.detectChanges();
  }

  editDetails() {
    if (
      this.userData.Name &&
      this.userData.Designation &&
      this.userData.Mobile
    ) {
      Swal.fire({
        title: 'Do you want to save the changes?',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: `Save`,
        denyButtonText: `Don't save`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          if (this.fileSelected == true) {
            const body = {
              UId: this.userData.UId,
            };

            this.userProfileService.getProfileByUId(body).subscribe((res) => {
              if (res.length > 0) {
                const updateBody = {
                  UId: this.userData.UId,
                  Image: this.base64textString[0],
                  updatedDate: new Date(Date.now())
                    .toLocaleDateString('en-GB')
                    .split(',')[0],
                };

                console.log(updateBody);

                this.userProfileService
                  .updateProfile(updateBody)
                  .subscribe((res) => {
                    if (res['Success'] == true) {
                      this.getProfile();
                    }
                  });
              } else {
                const createBody = {
                  UId: this.userData.UId,
                  Image: this.base64textString[0],
                  createdDate: new Date(Date.now())
                    .toLocaleDateString('en-GB')
                    .split(',')[0],
                };

                console.log(createBody);

                this.userProfileService
                  .addProfile(createBody)
                  .subscribe((res) => {
                    if (res['Success'] == true) {
                      this.getProfile();
                    }
                  });
              }
            });
          }

          this.createAccountService.editUser(this.userData).subscribe(
            () => {
              this.authService.updateUser(this.userData);
              Swal.fire('Updated!', '', 'success');
            },
            (err) => {
              alert(err.message);
              return;
            }
          );
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info');
        }
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `Data Can't Be Empty!`,
      });
    }
  }

  logout() {
    this.authService.logout().subscribe((success) => {
      if (success) {
        Swal.fire(
          'Logged Out!',
          'You have successfully logged out!.',
          'success'
        );
        this.router.navigate(['/']);
      }
    });

    /* Swal.fire({
      title: 'Are you sure?',
      text: "Do You Want to Logout !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Logout now!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.authService.logout().subscribe(success => {
          if (success) {
            Swal.fire(
              'Logged Out!',
              'You have successfully logged out!.',
              'success'
            )
            this.router.navigate(['/']);
          }
        })
      }
    }) */
  }
}
