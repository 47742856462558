export class SignUp {
  public UId: string;
  public Company_Name: string;
  public Mobile: number;
  public Email_ID: string;
  public Password: string;
  public Location: string;
  public Local_IP: string;
  public Profile_Type:string;
  public Name:string;
  public Designation:string;
  public UserName:string;
  public Date:string;
  public Time:string;
}
