import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from '../Common/config';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Asia/Kolkata');

declare var jQuery: any;
declare var $: any;

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  moment = moment;
  constructor(private http: HttpClient) {}

  getTotalCount(UId: any) {
    return this.http
      .get<any>(`${config.apiUrl}/fetchTotalCount?UId=${UId}`)
      .toPromise();
  }

  getTotalCountForK(UId: any) {
    return this.http
      .get<any>(`${config.apiUrl}/fetchTotalCountForK?UId=${UId}`)
      .toPromise();
  }

  InsertTotalCount(data) {
    return this.http.post<any>(`${config.apiUrl}/insertTotalCount`, data);
  }

  UpdateTotalcount(data) {
    return this.http.post<any>(`${config.apiUrl}/updateTotalCount`, data);
  }

  UpdateTotalEmployee(data) {
    return this.http.post<any>(`${config.apiUrl}/updateTotalEmployee`, data);
  }

  getAllTimeHeroes(UId: any) {
    return this.http
      .get<any>(`${config.apiUrl}/allTimeHeroes?UId=${UId}`)
      .toPromise();
  }

  getHeroesByMonth(UId: any) {
    return this.http
      .get<any>(`${config.apiUrl}/heroesByMonth?UId=${UId}`)
      .toPromise();
  }

  getTodayHeroes(data) {
    return this.http.post<any>(`${config.apiUrl}/todayHeroes`, data);
  }

  getWeeklyReport(data) {
    return this.http.post<any>(`${config.apiUrl}/weeklyReport`, data);
  }

  getMonthlyComplianceReports(UId: any) {
    return this.http
      .get<any>(`${config.apiUrl}/p-device/getMonthlyReport?UId=${UId}`)
      .toPromise();
  }

  getMonthlyReports(UId: any, month: any,model: any) {
    return this.http.get<any>(
      `${config.apiUrl}/monthlyReport?UId=${UId}&month=${month}&model=${model}`
    );
  }

  getMonthList(UId: any) {
    return this.http.get<any>(`${config.apiUrl}/monthList?UId=${UId}`);
  }

  makeRandomString(lengthOfCode: number) {
    let possible: string =
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let text = '';
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  monthList() {
    const monthList = Array.apply(0, Array(12)).map(function (_, i) {
      return { key: i, value: moment().month(i).format('MMMM') };
    });
    return monthList;
  }

  loadDatatbles(id: string) {
    (function ($) {
      $('#' + id).DataTable({
        paging: true,
        lengthChange: true,
        searching: true,
        ordering: true,
        info: true,
        autoWidth: true,
        responsive: false,
      });
    })(jQuery);
  }

  loadReportDatatables(id: string) {
    (function ($) {
      $('#' + id).DataTable({
        paging: true,
        searching: true,
        ordering: true,
        autoWidth: true,
        responsive: false,
        dom: 'Bfrtip',
        buttons: ['excel'],
      });
    })(jQuery);
  }
  // buttons: ['copy', 'csv', 'excel', 'print', 'pdf'],

  destroyDatatbles(id: string) {
    (function ($) {
      $('#' + id)
        .DataTable()
        .destroy();
    })(jQuery);
  }

  //for micro biology audit report
  getallUsers()
  {
    return this.http.get<any>(`${config.apiUrl}/userList`).toPromise();
  }

  getUserReports(id:string)
  {
    return this.http.get<any>(`${config.apiUrl}/userReportList?id=${id}`).toPromise();
  }

  removeAuditFile(id:any,fname:any)
  {
    this.deletefileviaphp(fname);
    return this.http.get<any>(`${config.apiUrl}/removeUserReport?id=${id}`).toPromise();
  }

  uploadfile(data:File, uid:string)
  {
    this.insertfileviaphp(data);
    let res={
      name:data.name,
      Uid:uid
    }
    return this.http.post<any>(`${config.apiUrl}/addUserReport`,res);
  }

  insertfileviaphp(data:File)
  {
    const formData = new FormData();  
    formData.append("myFile", data); 
    this.http.post<any>(`http://upload.microgo-smarthygiene.in/AuditReport/uploadFile.php`, formData).toPromise();
  }
  deletefileviaphp(fname:string){
    let res = new FormData();
    res.append('fname', fname);
    this.http.post<any>(`http://upload.microgo-smarthygiene.in/AuditReport/deleteFile.php`, res).toPromise();
  }
  //for micro biology audit report
}
