import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CardDetails } from '../../models/cardDetails';
import { NgxSpinnerService } from 'ngx-spinner';
import { SignUp } from 'src/app/models/sigup';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ODeviceService } from '../../services/o-device.service';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lite-plus-card',
  templateUrl: './lite-plus-card.component.html',
  styleUrls: ['./lite-plus-card.component.css'],
})
export class LitePlusCardComponent implements OnInit {
  cardData: CardDetails;
  allCards: CardDetails[];
  userData: SignUp;
  addUserToggle: boolean = true;
  editUserToggle: boolean = false;

  constructor(
    private authService: AuthService,
    private oDeviceService: ODeviceService,
    private toastrService: ToastrService,
    private chRef: ChangeDetectorRef,
    private commonService: CommonService,
    private spinner: NgxSpinnerService
  ) {
    this.cardData = new CardDetails();
  }

  ngOnInit(): void {
    this.userData = JSON.parse(sessionStorage.getItem(this.authService.USER));
    this.getAllCards();
  }

  getAllCards() {
    this.spinner.show();
    this.oDeviceService
      .getAllCards(this.userData.UId)
      .then((res) => {
        let { data } = res;
        this.allCards = data;
        this.chRef.detectChanges();
        this.loadDatatbles();
        this.spinner.hide();
      })
      .catch((error) => {
        this.spinner.hide();
        console.log(error);
      });
  }

  loadDatatbles() {
    this.commonService.loadReportDatatables('cardDetailsTable');
  }

  addCard() {
    this.spinner.show();
    this.cardData.UId = this.userData.UId;
    this.oDeviceService
      .addCardDetail(this.cardData)
      .then((res) => {
        this.spinner.hide();
        this.clearCardData();
        this.commonService.destroyDatatbles('cardDetailsTable');
        this.getAllCards();
        this.toastrService.success('Card Added Successfully!', 'Added!');
      })
      .catch((error) => {
        this.spinner.hide();
        this.clearCardData();
        this.toastrService.warning('Card already exist!', 'Oops!');
        console.log(error);
      });
  }

  clearCardData() {
    this.cardData = new CardDetails();
    this.editUserToggle = false;
    this.addUserToggle = true;
  }

  onDelete(Card_No: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: "Every Record Of this card will be deleted And You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.oDeviceService
          .deleteCardDetail({ Card_No, UId: this.userData.UId })
          .then((res) => {
            this.commonService.destroyDatatbles('cardDetailsTable');
            this.getAllCards();
            Swal.fire('Deleted!', 'Your card has been deleted.', 'success');
          })
          .catch((err) => {
            console.log('err :>> ', err);
          });
      }
    });
  }

  onEdit(data: CardDetails) {
    this.clearCardData();
    this.cardData = data;

    this.editUserToggle = true;
    this.addUserToggle = false;
  }

  editCard() {
    this.oDeviceService
      .editCardDetail({
        Id: this.cardData.Id,
        Card_Name: this.cardData.Card_Name,
        UId: this.userData.UId,
      })
      .then((res) => {
        if (res['success'] == true) {
          this.commonService.destroyDatatbles('cardDetailsTable');
          this.getAllCards();
          Swal.fire('Updated!', 'Your card has been updated.', 'success');
        } else {
          Swal.fire('error!', 'Something Went Wrong!', 'warning');
        }
      })
      .catch((err) => {
        console.log('err :>> ', err);
      });
    this.clearCardData();
  }
}
