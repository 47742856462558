import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  devices: any;
  hasDevice: boolean = false;

  constructor(private authService: AuthService, private router: Router) {}

  canActivate() {
    this.devices = JSON.parse(sessionStorage.getItem(this.authService.DEVICES));
    if (this.authService.isLoggedIn()) {
      console.log('auth this.devices :>> ', this.devices);
      if (this.devices.P_Device) {
        console.log('auth p');
        this.router.navigate(['/dashboard']);
      } else if (this.devices.K_Device) {
        console.log('auth k');
        this.router.navigate(['/dashboard-k']);
      } else if (this.devices.O_Device) {
        console.log('auth o');
        this.router.navigate(['/dashboard-o']);
      }
    }
    return !this.authService.isLoggedIn();
  }
}
