import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { CreateAccountService } from 'src/app/services/create-account.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { HasDevice } from 'src/app/models/hasDevice';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.css'],
})
export class OtpVerificationComponent implements OnInit {
  otpForm: FormGroup;
  loadingText: string = 'Redirecting To Dashboard ';
  ImageCollection: Array<Object> = [
    {
      image: 'assets/img/slider-images/IMG-20210505-WA0024.jpg',
      thumbImage: 'assets/img/slider-images/IMG-20210505-WA0024.jpg',
      alt: 'Right Way?',
      // title: 'Image 1',
    },
    {
      image: 'assets/img/slider-images/IMG-20210505-WA0029.jpg',
      thumbImage: 'assets/img/slider-images/IMG-20210505-WA0029.jpg',
      alt: 'Counts',
      // title: 'Image 1',
    },
    {
      image: 'assets/img/slider-images/IMG-20210505-WA0030.jpg',
      thumbImage: 'assets/img/slider-images/IMG-20210505-WA0030.jpg',
      alt: 'Service',
      // title: 'Image 1',
    },
    {
      image: 'assets/img/slider-images/IMG-20210505-WA0031.jpg',
      thumbImage: 'assets/img/slider-images/IMG-20210505-WA0031.jpg',
      alt: 'GOassure',
      // title: 'Image 1',
    },
    {
      image: 'assets/img/slider-images/IMG-20210505-WA0027.jpg',
      thumbImage: 'assets/img/slider-images/IMG-20210505-WA0027.jpg',
      alt: 'Contact',
      // title: 'Image 1',
    },
  ];
  fromSignup: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private createAccountService: CreateAccountService
  ) {}

  ngOnInit(): void {
    this.fromSignup = history.state?.fromSignup || this.fromSignup;
    this.otpForm = this.formBuilder.group({
      email: [
        {
          value: this.fromSignup ? history.state?.email : '',
          disabled: this.fromSignup,
        },
      ],
      otp: [''],
    });
  }

  resendOTP() {
    Swal.fire({
      title: 'Please enter email below!',
      text: 'You will recieve OTP on email address.',
      input: 'email',
    }).then((res: any) => {
      const { isConfirmed, value } = res;
      if (isConfirmed) {
        this.spinner.show();
        this.createAccountService.sendOTP({ email: value }).subscribe({
          next: (res) => {
            this.spinner.hide();
            this.toastrService.success(
              'OTP has been sent to your email address.',
              'Success!'
            );
          },
          error: (err) => {
            this.spinner.hide();
            const message = err.error.message;
            this.toastrService.warning(message, 'Oops!');
          },
        });
      }
      return;
    });
  }

  verifyOTP() {
    const body = {
      email: this.otpForm.controls.email.value,
      otp: this.otpForm.controls.otp.value,
    };

    this.createAccountService.verifyOTP(body).subscribe({
      next: (res) => {
        this.toastrService.success(res.message, 'Success!');
        setTimeout(() => {
          this.router.navigateByUrl('/login');
        }, 1000);
      },
      error: (err) => {
        this.toastrService.warning(err.error.message, 'Oops!');
      },
    });
  }
}
