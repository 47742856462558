const DIR_DEVICE = '../../assets/img/devices/';

const DeviceImages = {
  'K Lite': DIR_DEVICE + 'KLITE@600x-8.png',
  'K Max': DIR_DEVICE + 'KMAX@600x-8.png',
  'P Lite': DIR_DEVICE + 'PLITE@600x-8.png',
  'P Mini': DIR_DEVICE + 'PLITE@600x-8.png',
  'P Max': DIR_DEVICE + 'PMAX@600x-8.png',
  'O Lite': DIR_DEVICE + 'OLITE@600x-8.png',
  'O Max': DIR_DEVICE + 'OMAX@600x-8.png',
  'Lite+': DIR_DEVICE + 'LITE+@600x-8.png',
};

export default { DeviceImages };
