import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { SignUp } from '../../models/sigup';
import { IpServiceService } from '../../services/ip-service.service';
import { CommonService } from '../../services/common.service';
import { CreateAccountService } from '../../services/create-account.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/services/auth.service';
@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.css'],
})
export class CreateAccountComponent implements OnInit {
  private PersonalProfileType = 'P';
  private UIdLength = 6;
  private isUIdValid: boolean;
  signup: SignUp;
  private SC_Name = 'sc_';
  localPassword: string;
  isCheckValid: boolean;

  constructor(
    private authService: AuthService,
    private ipService: IpServiceService,
    private commonService: CommonService,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private createAccountService: CreateAccountService,
    private router: Router
  ) {
    this.signup = new SignUp();
  }

  ngOnInit(): void {
    this.resetForm();
  }

  resetForm(form?: NgForm) {
    this.signup = {
      UId: '',
      Company_Name: '',
      Mobile: null,
      Email_ID: '',
      Local_IP: '',
      Location: '',
      Password: '',
      Profile_Type: '',
      Date: '',
      Time: '',
      Designation: '',
      Name: '',
      UserName: '',
    };
  }

  createAccount(form?: NgForm) {
    this.spinner.show();
    if (this.localPassword != this.signup.Password) {
      this.toastrService.warning('Please Enter Same Password!', 'Oops!');
      return;
    }
    //get ip address
    this.ipService.getIPAddress().subscribe((res: any) => {
      this.signup.Local_IP = res['ip'];
    });

    //set Profile_Type, Date(dd/mm/yyyy) And Time(hh:mm:ss)
    this.signup.Profile_Type = this.PersonalProfileType;
    this.signup.Date = new Date(Date.now())
      .toLocaleDateString('en-GB')
      .split(',')[0];
    this.signup.Time = new Date()
      .toLocaleTimeString('en-US', { hour12: false })
      .slice(0, 8);

    //generate new UId and checking if it exists
    do {
      this.signup.UId = this.commonService.makeRandomString(this.UIdLength);
      this.createAccountService.getUser(this.signup.UId).subscribe((res) => {
        if (res.UId == this.signup.UId) {
          this.isUIdValid = true;
        } else {
          this.isUIdValid = false;
        }
      });
    } while (this.isUIdValid);
    //end of generating new UId

    this.createAccountService.signup(this.signup).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        this.router.navigateByUrl('/verify-otp', {
          state: { email: this.signup.Email_ID, fromSignup: true },
        });
      },
      error: (err: any) => {
        this.spinner.hide();
        this.toastrService.warning(err.error.message, 'Oops!');
      },
    });
  }
}
