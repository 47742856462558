import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SignUp } from 'src/app/models/sigup';
import { AuthService } from 'src/app/auth/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { KServiceService } from './../../services/k-service.service';
import { KReportModel, KALLReportModel } from '../../models/K-ReportModel';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-k-report',
  templateUrl: './k-report.component.html',
  styleUrls: ['./k-report.component.css'],
})
export class KReportComponent implements OnInit {
  userData: SignUp;
  onSubmitClick = false;
  showButton = false;
  toggleAll = false;
  dates = [];
  kReports: KReportModel[];
  AllReports: KALLReportModel[];
  selectedDate = '';
  selectedModel = '';
  totalSwipe = 0;

  constructor(
    private authService: AuthService,
    private chRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private toastrService: ToastrService,
    private kService: KServiceService
  ) {
    this.userData = new SignUp();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.userData = JSON.parse(sessionStorage.getItem(this.authService.USER));
    this.getDates();
    this.getTotalSwipe();
    this.chRef.detectChanges();
    this.spinner.hide();
  }

  getTotalSwipe() {
    this.kService.getTotalSwipe(this.userData.UId).then((res) => {
      this.totalSwipe = res.count;
      this.chRef.detectChanges();
    });
  }

  getDates() {
    this.kService
      .getDates(this.userData.UId)
      .then((res) => {
        this.dates = res.dates;
      })
      .catch((err) => {
        this.toastrService.warning('Something Went Wrong!', 'Oops!');
      });
  }

  async getReport() {
    this.spinner.show();
    this.showButton = false;
    if (this.onSubmitClick)
      this.commonService.destroyDatatbles('dataTableAllUser');

    this.kService
      .getReportsByDateAndModel(
        this.userData.UId,
        this.selectedDate,
        this.selectedModel
      )
      .then((res) => {
        this.kReports = res.data;
        this.onSubmitClick = true;
        this.chRef.detectChanges();
        this.loadDatatbles();
        this.spinner.hide();
      })
      .catch((err) => {
        this.spinner.hide();
        this.toastrService.warning('Something Went Wrong!', 'Oops!');
      });
  }

  async getAllReport() {
    this.spinner.show();
    this.kService
      .getAllReports(this.userData.UId)
      .then((res) => {
        this.AllReports = res.data;
        this.chRef.detectChanges();
        this.loadDatatbles();
        this.spinner.hide();
      })
      .catch((err) => {
        this.spinner.hide();
        this.toastrService.warning('Something Went Wrong!', 'Oops!');
      });
  }

  onDateSelected(event) {
    if (event.target.value != 0) {
      this.selectedDate = event.target.value;
      this.showButton = this.selectedModel && this.selectedDate ? true : false;
    }
  }

  onModelSelected(event) {
    if (event.target.value != 'All') {
      this.selectedModel = event.target.value === '1' ? 'K Lite' : 'K Max';
    } else {
      this.selectedModel = 'All';
    }
    this.showButton = this.selectedModel && this.selectedDate ? true : false;
  }

  loadDatatbles() {
    this.commonService.loadReportDatatables('dataTableAllUser');
  }

  changeToReport() {
    this.spinner.show();
    this.toggleAll = !this.toggleAll;
    if (this.toggleAll) {
      this.getAllReport();
      this.spinner.hide();
    } else {
      this.resetVariables();
      this.spinner.hide();
    }
  }

  resetVariables() {
    this.onSubmitClick = false;
    this.showButton = false;
    this.selectedDate = '';
    this.selectedModel = '';
    if (this.kReports && this.kReports.length) {
      while (this.kReports.length > 0) {
        this.kReports.pop();
      }
    }
  }
}
