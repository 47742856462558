import { UserProfile } from './../models/userProfile';
import { Injectable } from '@angular/core';
import { config } from '../Common/config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(private http: HttpClient) { }

  addProfile(data) {
    return this.http.post<any>(`${config.apiUrl}/add-user-profile`, data);
  }

  getProfileByUId(data) {
    return this.http.post<any>(`${config.apiUrl}/user-profile`, data);
  }

  updateProfile(data) {
    return this.http.post<any>(`${config.apiUrl}/update-user-profile`, data);
  }

}
