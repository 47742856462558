import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { SignUp } from 'src/app/models/sigup';

@Component({
  selector: 'app-entry-page',
  templateUrl: './entry-page.component.html',
  styleUrls: ['./entry-page.component.css'],
})
export class EntryPageComponent implements OnInit {
  name: string = 'Hygiene Hero';
  userData: SignUp;

  constructor(private authService: AuthService) {
    this.userData = new SignUp();
  }

  ngOnInit(): void {
    this.userData = JSON.parse(sessionStorage.getItem(this.authService.USER));
    this.name = this.userData.Name;
  }
}
