import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '../Common/config';
import { Device } from '../models/device';

@Injectable({
  providedIn: 'root',
})
export class DeviceDataService {
  allDevices: Device[];
  selectedDevice: Device;

  constructor(private http: HttpClient) {}

  addDevice(data: any) {
    return this.http.post<any>(`${config.apiUrl}/add-deviceData`, data);
  }

  findDeviceByUId(data: any) {
    return this.http.post<any>(`${config.apiUrl}/deviceDataByUId`, data);
  }

  deleteDeviceByDId(body: any) {
    return this.http.post<any>(`${config.apiUrl}/delete-deviceData`, body);
  }
}
