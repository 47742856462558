export interface CustomReportDeptData {
  Name: string;
  Dept: string;
  RFID_No: number;
  Preset_HHP: number;
  No_Of_User: number;
  Date: string;
  total: number;
  ExpectedSOP: number;
  ExpectedMonthlySOP: number;
  Device_Model: string;
  totalCompleted: number;
  totalIncompleted: number;
  Compliance: number;
}

export interface CustomReportRecord {
  Date: string;
  Time: string;
  Dept: string;
  Status: string;
}

export interface CustomReportMetadata {
  waterSavingToday: string;
  consumableToday: string;
  location: string;
  date: string;
  averageCompliance: string;
  totalUsageToday: number;
  totalCompletedUsageToday: number;
  totalIncompletedUsageToday: number;
}

export interface Data {
  deptData: CustomReportDeptData[];
  records: CustomReportRecord[];
  metadata: CustomReportMetadata;
}

export interface CustomReportRes {
  success: boolean;
  data: Data;
}

export class ShiftUserReg {
  public SC_Name: string;
  public Id: number;
  public UId: string;
  public RFID_No: number;
  public Name: string;
  public No_Of_User: string;
  public Dept: string;
  public Preset_HHP: number;
}
