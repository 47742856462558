import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable, firstValueFrom } from 'rxjs';
import { catchError, mapTo, tap, window } from 'rxjs/operators';
import { config } from '../../Common/config';
import { Tokens } from '../models/tokens';
import { SignUp } from 'src/app/models/sigup';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  readonly USER = 'USER';
  readonly DEVICES = 'DEVICES';
  public loggedUser: string;
  private loginResponse = {};
  constructor(private http: HttpClient) {}

  login(user: { username: string; password: string }): Observable<boolean> {
    return this.http.post<any>(`${config.apiUrl}/login`, user).pipe(
      tap((tokens) => {
        this.loginResponse = tokens;
        this.doLoginUser(user.username, tokens);
        this.doSaveUser(tokens['user']);
        this.doSaveDevices(tokens['devices']);
      }),
      mapTo(this.loginResponse),
      catchError((error) => {
        //alert(error.error);
        //alert("Please Enter Valid Username And Password")
        return of(error);
      })
    );
  }

  logout() {
    return this.http
      .post<any>(`${config.apiUrl}/logout`, {
        refreshToken: this.getRefreshToken(),
      })
      .pipe(
        tap(() => this.doLogoutUser()),
        mapTo(true),
        catchError((error) => {
          alert(error.error);
          return of(false);
        })
      );
  }

  isLoggedIn() {
    return !!this.getJwtToken();
  }

  async getAllDevices(UId: any, doReload = false) {
    firstValueFrom(
      this.http.get<any>(`${config.apiUrl}/checkDevice?UId=${UId}`),
      { defaultValue: false }
    )
      .then((res) => {
        this.doSaveDevices(res.devices);
        if (doReload) {
          location.reload();
        }
      })
      .catch((err) => {
        console.log('err :>> ', err);
        // this.doSaveDevices(err.devices);
      });
  }

  private doSaveDevices(devices: object) {
    sessionStorage.setItem(this.DEVICES, JSON.stringify(devices));
  }

  private getDevices(devices: object) {
    devices = JSON.parse(sessionStorage.getItem(this.DEVICES));
    return devices;
  }

  sendPassword(data) {
    return this.http.post<any>(`${config.apiUrl}/sendPassword`, data);
  }

  refreshToken() {
    return this.http
      .post<any>(`${config.apiUrl}/refresh`, {
        refreshToken: this.getRefreshToken(),
      })
      .pipe(
        tap((tokens: Tokens) => {
          this.storeJwtToken(tokens.jwt);
        })
      );
  }

  getJwtToken() {
    return sessionStorage.getItem(this.JWT_TOKEN);
  }

  private doLoginUser(username: string, tokens: Tokens) {
    this.loggedUser = username;
    this.storeTokens(tokens);
  }

  private doSaveUser(user: SignUp) {
    this.storeUser(user);
  }

  private doLogoutUser() {
    this.loggedUser = null;
    this.removeTokens();
  }

  private getRefreshToken() {
    return sessionStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeJwtToken(jwt: string) {
    sessionStorage.setItem(this.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens: Tokens) {
    sessionStorage.setItem(this.JWT_TOKEN, tokens.jwt);
    sessionStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }

  private storeUser(user: SignUp) {
    sessionStorage.setItem(this.USER, JSON.stringify(user));
  }

  public updateUser(user: SignUp) {
    sessionStorage.setItem(this.USER, JSON.stringify(user));
  }

  private removeTokens() {
    sessionStorage.removeItem(this.JWT_TOKEN);
    sessionStorage.removeItem(this.REFRESH_TOKEN);
    sessionStorage.removeItem(this.USER);
    sessionStorage.removeItem(this.DEVICES);
  }
}
