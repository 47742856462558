import { Component, OnInit } from '@angular/core';
import { CustomReportDeptData, CustomReportRecord } from 'src/app/models/customReportDetails';
import { ReportService } from 'src/app/services/report-service.service';
import { Chart, registerables } from 'chart.js';
import { CommonService } from 'src/app/services/common.service';
Chart.register(...registerables);

const pdfMake = require('pdfmake/build/pdfmake.js');
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-preport',
  templateUrl: './preport.component.html',
  styleUrls: ['./preport.component.css']
})
export class PReportComponent implements OnInit {
  metadata: any = {};
  customReportDeptData: CustomReportDeptData[] = [];
  customReportRecords: CustomReportRecord[] = [];
  complianceReport: any = [];
  columns: any[] = [];
  chartDataURL: any = '';

  constructor(
    public reportService: ReportService,
    private commonService: CommonService,
  ) { }

  ngOnInit(): void {
    this.getReportList();
  }

  async getReportList():Promise<void> {
    // this.chRef.detectChanges();
    var nowDate = new Date(); 
    
    var date = nowDate.getFullYear()+'-'+('0'+ (nowDate.getMonth()+1)).slice(-2)+'-'+('0'+ (nowDate.getDate()-1)).slice(-2);
    console.log(date);
    const requestBody = {
      SC_Name: "sc_5sbDNP",
      date: date,
      rfid: "",
      location: "Bqt Kitchen",
      deviceId: "3",
      dept: "KST",
      shift: "",
    };
    await this.reportService.getCustomReport(requestBody).subscribe({
      next: async (res) => {
        this.metadata = res.data.metadata;
        this.customReportDeptData = res.data.deptData;
        this.customReportRecords = res.data.records;

        let graphLabels = this.customReportDeptData.map(({ Dept }) => Dept);
        let graphData = this.customReportDeptData.map(
          ({ Compliance }) => Compliance
        );

        //this.chRef.detectChanges();
        if (
          this.complianceReport &&
          this.complianceReport != null &&
          !Array.isArray(this.complianceReport)
        )
          this.complianceReport.destroy();

        await this.fillComplianceChart(graphLabels, graphData);

        await this.loadDatatbles();
        
        //this.chRef.detectChanges();
      },
      error: (error) => {
      },
      
    });
  }

  poolColors(graphData: []) {
    let colours = [];
    let colors = {
      blue: 'rgba(0,0,255,1)',
      green: 'rgba(0, 255, 0,1)',
      red: 'rgba(255,0,0,1)',
    };
    for (let i = 0; i < graphData.length; i++) {
      if (Number(graphData[i]) >= 80) {
        colours.push(colors.green);
      } else if (Number(graphData[i]) > 25) {
        colours.push(colors.blue);
      } else {
        colours.push(colors.red);
      }
    }
    return colours;
  }

  async fillComplianceChart(graphLabel, graphData) {
    this.complianceReport = new Chart('complianceReport', {
      type: 'bar',
      data: {
        labels: graphLabel,
        datasets: [
          {
            // label: 'Compliance (%)',
            // backgroundColor: 'rgba(0,0,255,0.5)',
            backgroundColor: this.poolColors(graphData),
            barPercentage: 0.5,
            barThickness: 30,
            maxBarThickness: 50,
            minBarLength: 2,
            hoverBackgroundColor: 'rgba(0,0,255,0.7)',
            borderColor: 'rgba(0,0,255,1)',
            data: graphData,
          },
        ],
      },
      options: {
        devicePixelRatio: 1.5,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 25,
            top: 25,
            bottom: 0,
          },
        },
        scales: {
          xAxes: {
            title: {
              text: 'Deparment Names',
              display: true,
            },
            time: {
              unit: 'day',
            },
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              maxTicksLimit: 8,
            },
          },
          yAxes: {
            title: {
              text: 'Hand Hygiene Compliance %',
              display: true,
            },
            ticks: {
              minRotation: 0,
              maxRotation: 100,
              maxTicksLimit: 6,
              padding: 10,
              // Include a dollar sign in the ticks
              // callback: function(value, index, values) {
              //     return '$' + number_format(value);
              // }
            },
            grid: {
              color: 'rgb(234, 236, 244)',
              drawBorder: false,
              borderDash: [2],
            },
          },
        },
        plugins: {
          legend: {
            display: false,
            position: 'top',
            title: {
              text: 'Compliance(%)',
              color: '#000000',
              display: true,
            },
          },
          tooltip: {
            titleMarginBottom: 10,
            titleColor: '#6e707e',
            titleFont: {
              size: 14,
            },
            backgroundColor: 'rgb(255,255,255)',
            bodyColor: '#858796',
            borderColor: '#dddfeb',
            borderWidth: 1,
            padding: 15,
            displayColors: false,
            caretPadding: 10,
            /* callbacks: {
                  label: function (context) {
                    return `${Number(context.parsed.y)}`;
                  },
                }, */
          },
        },
      },
    });
  }

  async loadDatatbles():Promise<void> {
    await this.commonService.loadReportDatatables('dataTableAllUser');
    await this.commonService.loadReportDatatables('dataTableAllRecords');
    setTimeout(()=>{ this.downloadReport(); }, 7000)
    
  }

  

  

  getDocumentDefinition() {
    let rows = [];

    this.columns = [
      '#',
      'Department',
      'Present HHP\n[Shift Wise]',
      'No of Users',
      'Expected SOP',
      'Total\ncomplete usage',
      'Total\nincomplete usage',
      'Compliance',
      'Date',
    ];
    let finalColumns = this.columns.map((value) => {
      return { text: value, alignment: 'center', style: 'header' };
    });

    let goAssureHeader = [];
    goAssureHeader.push({
      text: [
        'GO',
        {
          text: 'assure',
          italics: true,
        },
        ' - SMART Hand Hygiene Station',
      ],
      colSpan: this.columns.length,
      style: 'headerBig',
      alignment: 'center',
    });

    let footerData = [];
    footerData.push({
      columns: [
        {
          text: [
            { text: '   ', background: '#ff0000' },
            ' : Compliance (%) less than 25',
          ],
          fontSize: 8,
        },
        {
          text: [
            { text: '   ', background: '#0000ff' },
            ' : Compliance (%) between 25 to 80',
          ],
          fontSize: 8,
        },
        {
          text: [
            { text: '   ', background: '#00ff00' },
            ' : Compliance (%) greater than equal to 80',
          ],
          fontSize: 8,
        },
      ],
      alignment: 'center',
    });

    for (let i = 1; i < this.columns.length; i++) {
      goAssureHeader.push({ text: '' });
    }

    let width = (100 / finalColumns.length).toFixed(2),
      widths = [];

    for (let i = 0; i < finalColumns.length; i++) {
      widths.push(`${width}%`);
    }

    let singleRow = [],
      singleRecord: CustomReportDeptData = null,
      background = '';

    for (let i = 0; i < this.customReportDeptData.length; i++) {
      singleRecord = this.customReportDeptData[i];
      singleRow = [];
      singleRow.push({
        text: i + 1,
        fontSize: 6,
        alignment: 'center',
      });
      singleRow.push({
        text: singleRecord.Dept,
        fontSize: 6,
        alignment: 'center',
      });
      singleRow.push({
        text: singleRecord.Preset_HHP,
        fontSize: 6,
        alignment: 'center',
      });
      singleRow.push({
        text: singleRecord.No_Of_User,
        fontSize: 6,
        alignment: 'center',
      });

      singleRow.push({
        text: singleRecord.ExpectedSOP,
        fontSize: 6,
        alignment: 'center',
      });
      singleRow.push({
        text: singleRecord.totalCompleted,
        fontSize: 6,
        alignment: 'center',
      });
      singleRow.push({
        text: singleRecord.totalIncompleted,
        fontSize: 6,
        alignment: 'center',
      });
      singleRow.push({
        text: singleRecord.Compliance,
        fontSize: 6,
        alignment: 'center',
      });
      singleRow.push({
        text: singleRecord.Date,
        fontSize: 6,
        alignment: 'center',
      });

      rows.push(singleRow);
    }

    let firstRow = [],
      secondRow = [],
      thirdRow = [];

    const metadata = this.metadata;

    firstRow = [
      {
        text: `Company/Location:  Grand Chennai by GRT Hotels, Chennai`,
        colSpan: 3,
        alignment: 'center',
        fontSize: 8,
        border: [true, false, false, false],
      },
      {
        text: '',
      },
      {
        text: '',
      },
      {
        text: `Water saving today: ${metadata.waterSavingToday} L`,
        colSpan: 3,
        alignment: 'center',
        fontSize: 8,
        border: [false, false, false, false],
      },
      {
        text: '',
      },
      {
        text: '',
      },
      {
        text: `Consumable usage today: ${metadata.consumableToday} L`,
        colSpan: 3,
        alignment: 'center',
        fontSize: 8,
        border: [false, false, true, false],
      },
      {
        text: '',
      },
      {
        text: '',
      },
    ];

    secondRow = [
      {
        text: `Total usage today: ${metadata.totalUsageToday}`,
        colSpan: 3,
        alignment: 'center',
        fontSize: 8,
        border: [true, false, false, false],
      },
      {
        text: '',
      },
      {
        text: '',
      },
      {
        text: `Total complete usage today: ${metadata.totalCompletedUsageToday}`,
        colSpan: 3,
        alignment: 'center',
        fontSize: 8,
        border: [false, false, false, false],
      },
      {
        text: '',
      },
      {
        text: '',
      },
      {
        text: `Total incomplete usage today: ${metadata.totalIncompletedUsageToday}`,
        colSpan: 3,
        alignment: 'center',
        fontSize: 8,
        border: [false, false, true, false],
      },
      {
        text: '',
      },
      {
        text: '',
      },
    ];

    thirdRow = [
      {
        text: `Date: ${metadata.date}`,
        colSpan: 3,
        alignment: 'center',
        fontSize: 8,
        border: [true, false, false, false],
      },
      {
        text: '',
      },
      {
        text: '',
      },
      {
        text: `Average Compliance: ${metadata.averageCompliance}`,
        colSpan: 3,
        alignment: 'center',
        fontSize: 8,
        border: [false, false, false, false],
      },
      {
        text: '',
      },
      {
        text: '',
      },
      {
        text: `SOP definition: Based on Shift Wise`,
        colSpan: 3,
        alignment: 'center',
        fontSize: 8,
        border: [false, false, true, false],
      },
      {
        text: '',
      },
      {
        text: '',
      },
    ];

    return {
      info: {
        title: 'Micro GO - P-Custom-Report',
        author: 'Micro GO LLP',
        subject: 'P-Custom-Report',
      },
      pageOrientation: 'landscape',
      content: [
        {
          style: 'headerTable',
          table: {
            widths: ['35%', '35%', '34%', '0%', '0%', '0%'],
            body: [
              [
                {},
                {
                  text: 'MicroGO | P Custom-Report',
                  style: 'headerBig',
                  alignment: 'center',
                  margin: [0, 5, 0, 0],
                },
                {},
              ],
            ],
          },
          layout: 'noBorders',
        },
        { text: '', margin: [0, 5, 0, 0] },
        {
          style: 'tableExample',
          table: {
            widths: widths,
            body: [
              goAssureHeader,
              firstRow,
              secondRow,
              thirdRow,
              finalColumns,
              ...rows,
            ],
          },
          layout: {
            fillColor: function (rowIndex: any) {
              return rowIndex >= 2 && rowIndex % 2 === 0 ? '#f0eded' : null;
            },
          },
        },
        {
          table: {
            widths: ['*'],
            body: [
              [
                {
                  image: 'chart',
                  fit: [500, 500],
                  alignment: 'center',
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: ['*'],
            body: [footerData],
          },
        },
        {
          text: '**only Set SOP data will be visible , above SOP usage count can be checked in the total count . For details check "View all reports" in the website.',
          fontSize: 8,
          alignment: 'center',
          margin: [0, 0, 0, 3],
        },
        {
          text: 'Please note: Overall Compliance % on the dashboard  is calculated as complete vs Incomplete cycle whereas on the daily report, the compliance % is reported as defined by the SOP required by you',
          fontSize: 8,
          alignment: 'center',
        },
      ],
      images: {
        chart: this.chartDataURL,
      },
      styles: {
        headerBig: {
          fontSize: 14,
          bold: true,
          alignment: 'justify',
          margin: [0, 5, 0, 5],
        },
        header: {
          fontSize: 6,
          bold: true,
          alignment: 'justify',
          margin: [0, 5, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
          alignment: 'justify',
        },
        headerTable: {
          alignment: 'justify',
        },
      },
    };
  }

  async convertHtmlToImageDataURL():Promise<void> {
    this.chartDataURL =await this.complianceReport.toBase64Image();
  }

  async downloadReport() {
    await this.convertHtmlToImageDataURL();
    var res=pdfMake
      .createPdf(this.getDocumentDefinition());
      res.getBase64((data)=>{
        const requestBody = {
          attachment: data
        };
        this.reportService
      .sendautomailer(requestBody)
      .subscribe();
      });
      
  }
}
