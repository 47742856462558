import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { CreateAccountService } from 'src/app/services/create-account.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { HasDevice } from 'src/app/models/hasDevice';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loadingText: string = 'Redirecting To Dashboard ';
  devices: HasDevice;

  loginImageCollection: Array<Object> = [
    {
      image: 'assets/img/slider-images/IMG-20210505-WA0024.jpg',
      thumbImage: 'assets/img/slider-images/IMG-20210505-WA0024.jpg',
      alt: 'Right Way?',
      // title: 'Image 1',
    },
    {
      image: 'assets/img/slider-images/IMG-20210505-WA0029.jpg',
      thumbImage: 'assets/img/slider-images/IMG-20210505-WA0029.jpg',
      alt: 'Counts',
      // title: 'Image 1',
    },
    {
      image: 'assets/img/slider-images/IMG-20210505-WA0030.jpg',
      thumbImage: 'assets/img/slider-images/IMG-20210505-WA0030.jpg',
      alt: 'Service',
      // title: 'Image 1',
    },
    {
      image: 'assets/img/slider-images/IMG-20210505-WA0031.jpg',
      thumbImage: 'assets/img/slider-images/IMG-20210505-WA0031.jpg',
      alt: 'GOassure',
      // title: 'Image 1',
    },
    {
      image: 'assets/img/slider-images/IMG-20210505-WA0027.jpg',
      thumbImage: 'assets/img/slider-images/IMG-20210505-WA0027.jpg',
      alt: 'Contact',
      // title: 'Image 1',
    },
  ];

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private createAccountService: CreateAccountService
  ) {
    this.devices = new HasDevice();
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: [''],
      password: [''],
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  login() {
    this.spinner.show();
    this.authService
      .login({
        username: this.f.username.value,
        password: this.f.password.value,
      })
      .subscribe({
        next: (res: any) => {
          this.spinner.hide();
          if (res?.error?.success == false) {
            Swal.fire({
              icon: 'info',
              title: 'Oops...',
              text: res?.error?.message,
            });
          } else {
            this.devices = JSON.parse(
              sessionStorage.getItem(this.authService.DEVICES)
            );
            let { K_Device, O_Device, P_Device, LitePlus_Device } =
              this.devices;
            if (P_Device) {
              this.router.navigate(['/dashboard']);
            } else if (K_Device) {
              this.router.navigate(['/dashboard-k']);
            } else if (O_Device) {
              this.router.navigate(['/dashboard-o']);
            } else if (LitePlus_Device) {
              this.router.navigate(['/dashboard-lite-plus']);
            } else {
              this.router.navigate(['/home']);
            }
          }
        },
        error: (err: any) => {
          Swal.fire({
            icon: 'info',
            title: 'Oops...',
            text: err?.error?.message,
          });
        },
      });
  }

  verifyOTP() {
    this.router.navigateByUrl('/verify-otp', {
      state: { fromSignup: false },
    });
  }

  forgotPassword() {
    let OTP = Math.floor(100000 + Math.random() * 900000);
    let base64data = btoa(OTP.toString());

    Swal.fire({
      title: 'Please Enter Your Email Address!',
      text: 'Secret Code will be sent to your email address, For account recovery.',
      input: 'email',
    }).then((data: any) => {
      this.loadingText = 'Loading...';
      this.spinner.show();
      this.createAccountService
        .sendOTP({ email: data.value, otp: base64data })
        .subscribe((res) => {
          this.spinner.hide();
          if (res['Success'] == true) {
            Swal.fire({
              title: 'Please enter 6 Digit Code!',
              text: 'After verifying you will recieve your password.',
              input: 'text',
            })
              .then((res: any) => {
                this.spinner.show();
                if (res.value == OTP) {
                  this.authService
                    .sendPassword({ email: data.value })
                    .subscribe((res) => {
                      this.spinner.hide();
                      if (res['Success'] == true) {
                        Swal.fire(
                          'Password Sent Successfully!',
                          'Check Your Email Address To Recover Your Password!.',
                          'success'
                        );
                      } else {
                        Swal.fire({
                          icon: 'info',
                          title: 'Oops...',
                          text: 'Something Went wrong!',
                        });
                      }
                    });
                } else {
                  this.spinner.hide();
                  Swal.fire({
                    icon: 'info',
                    title: 'Oops...',
                    text: 'Invalid Code!',
                  });
                }
              })
              .catch((err) => {
                this.spinner.hide();
                if (err) {
                  Swal.fire({
                    icon: 'info',
                    title: 'Oops...',
                    text: 'Please Enter Valid Code!',
                  });
                }
              });
          }
        });
    });
  }
}
