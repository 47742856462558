import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { SignUp } from 'src/app/models/sigup';
import { AuthService } from 'src/app/auth/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ODeviceService } from 'src/app/services/o-device.service';
import {
  OReportModel,
  OAllOpportunityModel,
  OAllUsageModel,
} from './../../models/O-ReportModel';
import { Chart, registerables } from 'chart.js';
import html2canvas from 'html2canvas';

Chart.register(...registerables);

const pdfMake = require('pdfmake/build/pdfmake.js');
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-lite-plus-report',
  templateUrl: './lite-plus-report.component.html',
  styleUrls: ['./lite-plus-report.component.css'],
})
export class LitePlusReportComponent implements OnInit {
  // @ViewChild('graph', { read: ElementRef }) graph: ElementRef;

  userData: SignUp;
  onSubmitClick = false;
  showButton = false;
  showDownloadButton = false;
  toggleAllOpportunity = false;
  toggleAllUsage = false;
  toggleAll = false;
  dates = [];
  oReports: OReportModel[] = [];
  allOpportunityReports: OAllOpportunityModel[];
  allUsageReports: OAllUsageModel[];
  totalOpportunity = 0;
  startDate: any;
  endDate: any;
  chartDataURL: any = '';

  hasReportData = false;
  complianceReport: any = [];

  columns = [
    { key: 'index', value: 'Sr No.' },
    { key: 'Device_Name', value: 'Device Name' },
    { key: 'Date', value: 'Date' },
    { key: 'Location', value: 'Location' },
    { key: 'Total_Opportunity', value: 'Total Opportunity' },
    { key: 'Total_Complete_Usage', value: 'Total Completed Usage' },
    { key: 'Total_Incomplete_Usage', value: 'Total Incompleted Usage' },
    { key: 'Compliance', value: 'Compliance' },
  ];

  image1: any;
  image2: any;

  constructor(
    private oDeviceService: ODeviceService,
    private authService: AuthService,
    private chRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private toastrService: ToastrService
  ) {
    this.userData = new SignUp();
  }

  async ngOnInit() {
    this.spinner.show();
    this.userData = JSON.parse(sessionStorage.getItem(this.authService.USER));
    this.getTotalOpportunity();
    this.chRef.detectChanges();
    this.spinner.hide();
  }

  async convertHtmlToImageDataURL() {
    /*  let data = element;
      let response = await html2canvas(data);
      const contentDataURL = response.toDataURL('image/png');
      this.chartDataURL = contentDataURL; */

    this.chartDataURL = this.complianceReport.toBase64Image();
  }

  getDocumentDefinition() {
    let columns = this.columns.map(({ value }) => {
      return { text: value, alignment: 'center', style: 'header' };
    });

    let rows = [];
    let singleRow = [];
    if (this.oReports.length > 0) {
      for (let i = 0; i < this.oReports.length; i++) {
        singleRow = [];
        let obj = this.oReports[i];
        this.columns.forEach(({ key }) => {
          key !== 'index'
            ? singleRow.push({
                text: obj[key],
                alignment: key === 'Compliance' ? 'right' : 'center',
                fontSize: 10,
              })
            : singleRow.push({
                text: i + 1,
                alignment: 'center',
                fontSize: 10,
              });
        });
        rows.push(singleRow);
      }
    }

    let startDate = new Date(this.startDate).toISOString().split('T')[0];
    let endDate = new Date(this.endDate).toISOString().split('T')[0];

    let averageCompliance =
      this.oReports.length > 1
        ? this.oReports
            .map((item) => item.Compliance)
            .reduce((prev, next) => Number(prev) + Number(next))
            .toFixed()
        : this.oReports[0].Compliance;

    averageCompliance = (
      Number(averageCompliance) / this.oReports.length
    ).toFixed();

    let footerData = [];
    footerData.push({
      columns: [
        {
          text: [
            { text: '   ', background: '#ff0000' },
            ' : Compliance (%) less than 25',
          ],
          fontSize: 8,
        },
        {
          text: [
            { text: '   ', background: '#0000ff' },
            ' : Compliance (%) between 25 to 80',
          ],
          fontSize: 8,
        },
        {
          text: [
            { text: '   ', background: '#00ff00' },
            ' : Compliance (%) greater than equal to 80',
          ],
          fontSize: 8,
        },
      ],
      alignment: 'center',
    });

    return {
      info: {
        title: 'MicroGO - Lite+ Report',
        author: 'MicroGO LLP',
        subject: 'Lite+ Report',
      },
      content: [
        {
          style: 'headerTable',
          table: {
            widths: ['35%', '35%', '34%', '0%', '0%', '0%'],
            body: [
              [
                {},
                {
                  text: 'MicroGo | Lite+ Report',
                  style: 'headerBig',
                  alignment: 'center',
                  margin: [0, 25, 0, 0],
                },
                {},
              ],
            ],
          },
          layout: 'noBorders',
        },
        { text: '', margin: [0, 20, 0, 0] },
        {
          style: 'tableExample',
          table: {
            body: [
              [
                {
                  text: [
                    'GO',
                    {
                      text: 'assure',
                      italics: true,
                    },
                    ' - SMART Hand Hygiene Station',
                  ],
                  colSpan: 8,
                  style: 'headerBig',
                  alignment: 'center',
                },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
              ],
              [
                {
                  text: `Date : ${startDate} to ${endDate}`,
                  colSpan: 5,
                  style: 'headerBig',
                  alignment: 'center',
                },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                {
                  text: `Average Compliance : ${averageCompliance}%`,
                  colSpan: 3,
                  style: 'headerBig',
                  alignment: 'center',
                },
                { text: '' },
                { text: '' },
              ],
              columns,
              ...rows,
            ],
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return rowIndex >= 2 && rowIndex % 2 === 0 ? '#ECEAEA' : null;
            },
          },
        },
        {
          table: {
            widths: ['*'],
            body: [
              [
                {
                  image: 'chart',
                  fit: [500, 500],
                  alignment: 'center',
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: ['*'],
            body: [footerData],
          },
        },
      ],
      images: {
        chart: this.chartDataURL,
      },
      styles: {
        headerBig: {
          fontSize: 14,
          bold: true,
          alignment: 'justify',
          margin: [0, 5, 0, 5],
        },
        header: {
          fontSize: 12,
          bold: true,
          alignment: 'justify',
          margin: [0, 5, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
          alignment: 'justify',
        },
        headerTable: {
          alignment: 'justify',
        },
      },
    };
  }

  async openPdf() {
    // const documentDefinition = this.getDocumentDefinition();
    // let dom = JSON.stringify(documentDefinition);
    // dom = JSON.parse(dom);
    await this.convertHtmlToImageDataURL();
    pdfMake.createPdf(this.getDocumentDefinition()).open();
  }

  async downloadReport() {
    let startDate = new Date(this.startDate).toISOString().split('T')[0];
    let endDate = new Date(this.endDate).toISOString().split('T')[0];

    await this.convertHtmlToImageDataURL();
    pdfMake
      .createPdf(this.getDocumentDefinition())
      .download(`MicroGo - Lite+ Report ${startDate} to ${endDate}`);
  }

  toDataURL(url: any) {
    let dataURL;
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        dataURL = reader.result;
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
    return dataURL;
  }

  dateRangeChange() {
    if (this.startDate && this.endDate) {
      this.showButton = true;
    } else {
      this.showButton = false;
    }
  }

  getTotalOpportunity() {
    this.oDeviceService
      .getTotalOpportunity(this.userData.UId, true)
      .then((res) => {
        this.totalOpportunity = res.data;
        this.chRef.detectChanges();
      });
  }

  async getReport() {
    if (!this.startDate || !this.endDate) {
      this.toastrService.warning('Please select date range!', 'Required!');
      return;
    }

    let startDate = new Date(this.startDate).toISOString().split('T')[0];
    let endDate = new Date(this.endDate).toISOString().split('T')[0];

    this.spinner.show();
    this.showButton = false;
    this.showDownloadButton = true;
    if (this.onSubmitClick)
      this.commonService.destroyDatatbles('dataTableAllUser');

    this.oDeviceService
      .getReportByDate(this.userData.UId, startDate, endDate, true)
      .then((res) => {
        this.oReports = res.data;
        if (!this.oReports.length) this.showDownloadButton = false;
        this.onSubmitClick = true;

        if (this.oReports.length) {
          this.hasReportData = true;
          this.chRef.detectChanges();
          let uniqueDevices = [
            ...new Set(this.oReports.map(({ Device_MAC }) => Device_MAC)),
          ];

          let graphLabels = [],
            graphData = [],
            label = null,
            Compliance = null;

          for (let i = 0; i < uniqueDevices.length; i++) {
            label = this.oReports.find(({ Device_MAC }) => {
              return Device_MAC === uniqueDevices[i];
            });
            label = label['Device_Name'];

            let ComplianceArray = this.oReports
              .filter(({ Device_MAC }) => Device_MAC == uniqueDevices[i])
              .map((item) => item.Compliance);

            Compliance =
              ComplianceArray.length > 1
                ? ComplianceArray.reduce(
                    (prev, next) => Number(prev) + Number(next)
                  ).toFixed()
                : ComplianceArray[0];

            /* Compliance =
                this.oReports.length > 1
                  ? this.oReports
                      .filter(({ Device_MAC }) => Device_MAC == uniqueDevices[i])
                      .map((item) => item.Compliance)
                      .reduce((prev, next) => Number(prev) + Number(next))
                      .toFixed()
                  : this.oReports
                      .filter(({ Device_MAC }) => Device_MAC == uniqueDevices[i])
                      .map((item) => item.Compliance)[0]; */

            graphLabels.push(label);
            graphData.push(Compliance);
          }

          this.fillComplianceChart(graphLabels, graphData);
        }
        this.chRef.detectChanges();
        this.loadDatatbles();
        this.spinner.hide();
      })
      .catch((err) => {
        console.log('err :>> ', err);
        this.oReports = [];
        this.commonService.destroyDatatbles('dataTableAllUser');
        this.showDownloadButton = false;
        this.spinner.hide();
        this.toastrService.warning('Record not found!', 'Oops!');
      });
  }

  fillComplianceChart(graphLabel, graphData) {
    this.complianceReport = new Chart('complianceReport', {
      type: 'bar',
      data: {
        labels: graphLabel,
        datasets: [
          {
            label: 'Compliance',
            backgroundColor: this.poolColors(graphData),
            barPercentage: 0.5,
            barThickness: graphData.length > 10 ? 10 : 30,
            maxBarThickness: graphData.length > 10 ? 30 : 50,
            minBarLength: 2,
            hoverBackgroundColor: 'rgba(0,0,255,0.7)',
            borderColor: 'rgba(0,0,255,1)',
            data: graphData,
          },
        ],
      },
      options: {
        devicePixelRatio: 1.5,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 25,
            top: 25,
            bottom: 0,
          },
        },
        scales: {
          xAxes: {
            title: {
              text: 'Devices',
              display: true,
            },
            time: {
              unit: 'day',
            },
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              // maxTicksLimit: 8,
            },
          },
          yAxes: {
            title: {
              text: 'Compliance %',
              display: true,
            },
            ticks: {
              minRotation: 0,
              maxRotation: 100,
              // maxTicksLimit: 6,
              padding: 10,
              // Include a dollar sign in the ticks
              // callback: function(value, index, values) {
              //     return '$' + number_format(value);
              // }
            },
            grid: {
              color: 'rgb(234, 236, 244)',
              drawBorder: false,
              borderDash: [2],
            },
          },
        },
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          tooltip: {
            titleMarginBottom: 10,
            titleColor: '#6e707e',
            titleFont: {
              size: 14,
            },
            backgroundColor: 'rgb(255,255,255)',
            bodyColor: '#858796',
            borderColor: '#dddfeb',
            borderWidth: 1,
            padding: 15,
            displayColors: false,
            caretPadding: 10,
            /* callbacks: {
                    label: function (context) {
                      return `${Number(context.parsed.y)}`;
                    },
                  }, */
          },
        },
      },
    });
  }

  poolColors(graphData: []) {
    let colours = [];
    let colors = {
      blue: 'rgba(0,0,255,1)',
      green: 'rgba(0, 255, 0,1)',
      red: 'rgba(255,0,0,1)',
    };
    for (let i = 0; i < graphData.length; i++) {
      if (Number(graphData[i]) >= 80) {
        colours.push(colors.green);
      } else if (Number(graphData[i]) > 25) {
        colours.push(colors.blue);
      } else {
        colours.push(colors.red);
      }
    }
    return colours;
  }

  async getAllOpportunityReport() {
    this.spinner.show();
    this.oDeviceService
      .getAllOpportunityReport(this.userData.UId, true)
      .then((res) => {
        this.allOpportunityReports = res.data;
        this.chRef.detectChanges();
        this.loadDatatbles();
        this.spinner.hide();
      })
      .catch((err) => {
        this.spinner.hide();
        this.toastrService.warning('Something Went Wrong!', 'Oops!');
      });
  }

  async getAllUsageReport() {
    this.spinner.show();
    this.oDeviceService
      .getAllUsageReport(this.userData.UId, true)
      .then((res) => {
        this.allUsageReports = res.data;
        this.chRef.detectChanges();
        this.loadDatatbles();
        this.spinner.hide();
      })
      .catch((err) => {
        this.spinner.hide();
        this.toastrService.warning('Something Went Wrong!', 'Oops!');
      });
  }

  loadDatatbles() {
    this.commonService.loadReportDatatables('dataTableAllUser');
  }

  changeToOpportunityReport() {
    this.spinner.show();
    this.toggleAllOpportunity = !this.toggleAllOpportunity;
    this.toggleAll = !this.toggleAll;
    if (this.toggleAllOpportunity && this.toggleAll) {
      this.getAllOpportunityReport();
      this.spinner.hide();
    } else {
      this.resetVariables();
      this.spinner.hide();
    }
  }

  changeToUsageReport() {
    this.spinner.show();
    this.toggleAllUsage = !this.toggleAllUsage;
    this.toggleAll = !this.toggleAll;
    if (this.toggleAllUsage && this.toggleAll) {
      this.getAllUsageReport();
      this.spinner.hide();
    } else {
      this.resetVariables();
      this.spinner.hide();
    }
  }

  changeToReport() {
    this.spinner.show();
    this.toggleAll = !this.toggleAll;
    this.resetVariables();
    this.spinner.hide();
  }

  resetVariables() {
    this.onSubmitClick = false;
    this.showButton = false;
    this.toggleAllUsage = false;
    this.toggleAllOpportunity = false;
    this.startDate = '';
    this.endDate = '';
    if (this.oReports && this.oReports.length) {
      while (this.oReports.length > 0) {
        this.oReports.pop();
      }
    }
  }
}
