import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '../Common/config';
import { SignUp } from '../models/sigup';

@Injectable({
  providedIn: 'root',
})
export class CreateAccountService {
  constructor(private http: HttpClient) {}

  getUser(_id: string) {
    return this.http.get<SignUp>(`${config.apiUrl}/parentData/` + `${_id}`);
  }

  getUserByEmail(email) {
    return this.http.post<any>(`${config.apiUrl}/parentData/byEmail`, email);
  }

  getUserByUsernme(username) {
    return this.http.post<any>(
      `${config.apiUrl}/parentData/byUsername`,
      username
    );
  }

  editUser(user) {
    return this.http.post<SignUp>(
      `${config.apiUrl}/update-parentData/` + `${user.UId}`,
      user
    );
  }

  signup(user) {
    return this.http.post<any>(`${config.apiUrl}/add-parentData`, user);
  }

  buildSchemaAndTables(user) {
    return this.http.post<any>(`${config.apiUrl}/buildschemaandtables`, user);
  }

  sendOTP(data) {
    return this.http.post<any>(`${config.apiUrl}/sendmail`, data);
  }

  verifyOTP(data) {
    return this.http.post<any>(`${config.apiUrl}/verifyUser`, data);
  }
}
