import { CreateAccountService } from './../../services/create-account.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SignUp } from 'src/app/models/sigup';
import { Device } from 'src/app/models/device';
import { AuthService } from 'src/app/auth/services/auth.service';
import { DeviceDataService } from 'src/app/services/device-data.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constant } from '../../Common';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.css'],
})
export class DeviceComponent implements OnInit {
  userData: SignUp;
  deviceData: Device;
  deviceImages: any = {};
  model: any;
  constructor(
    private chRef: ChangeDetectorRef,
    public deviceDataService: DeviceDataService,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private router: Router
  ) {
    this.userData = new SignUp();
    this.deviceData = new Device();
    this.deviceImages = Constant.DeviceImages;
  }

  ngOnInit(): void {
    this.spinner.show();
    this.userData = JSON.parse(sessionStorage.getItem(this.authService.USER));
    this.refreshDeviceList();
    this.spinner.hide();
  }

  async refreshDeviceList() {
    this.spinner.show();
    this.deviceDataService.selectedDevice = {
      SC_Name: '',
      Location: '',
      DId: null,
      UId: '',
      Date: '',
      Device_MAC: '',
      Device_Model: '',
      Device_Name: '',
      Time: '',
    };

    this.deviceData = {
      DId: null,
      Date: '',
      Device_MAC: '',
      Device_Name: '',
      Device_Model: '',
      Location: '',
      SC_Name: '',
      Time: '',
      UId: '',
    };

    const body = {
      UId: this.userData.UId,
      SC_Name: 'sc_' + this.userData.UId,
    };

    await this.deviceDataService.findDeviceByUId(body).subscribe((res) => {
      this.deviceDataService.allDevices = res as Device[];
    });

    this.chRef.detectChanges();
    this.spinner.hide();
  }

  addDevice() {
    if (
      !this.deviceData.Device_Model ||
      !this.deviceData.Location ||
      !this.deviceData.Device_MAC
    ) {
      this.toastrService.warning("Fields Can't Be Empty", 'Required!');
      return;
    }

    this.spinner.show();
    this.deviceData.SC_Name = 'sc_' + this.userData.UId;
    this.deviceData.UId = this.userData.UId;
    this.deviceData.Date = new Date(Date.now())
      .toLocaleDateString('en-GB')
      .split(',')[0];
    this.deviceData.Time = new Date()
      .toLocaleTimeString('en-US', { hour12: false })
      .slice(0, 8);

    this.deviceDataService.addDevice(this.deviceData).subscribe(
      async (data) => {
        if (data) {
          await this.authService.getAllDevices(this.userData.UId, true);
          this.spinner.hide();
          this.toastrService.success('Device Added Successfully!', 'Added!');
          // window.location.reload();
        } else {
          this.spinner.hide();
          this.toastrService.warning('Something Went Wrong!', 'Oops!');
        }
        this.refreshDeviceList();
      },
      (failure) => {
        const {
          error: { message },
        } = failure;
        this.spinner.hide();
        this.toastrService.warning(message, 'Oops!');
        this.refreshDeviceList();
      }
    );
  }

  onSetting(device: Device) {
    this.deviceDataService.selectedDevice = null;
    this.deviceDataService.selectedDevice = device;
    this.getModelKey(this.deviceDataService.selectedDevice.Device_Model);
  }

  getImage(modelName?: any) {
    return this.deviceImages[modelName]
      ? this.deviceImages[modelName]
      : 'assets/img/microgo_posters/Renders/Artboard 1_.jpg';
  }

  getModelKey(modelName?: any) {
    this.model = modelName
      ? Object.keys(Constant.DeviceImages).filter(function (key) {
          return Constant.DeviceImages[key] === modelName;
        })[0]
      : '';
  }

  removeDevice(deviceId: Number, mac: any) {
    this.spinner.show();
    this.deviceDataService
      .deleteDeviceByDId({
        SC_Name: 'sc_' + this.userData.UId,
        DId: deviceId,
        mac,
      })
      .subscribe(async (data) => {
        if (data['success'] == true) {
          this.refreshDeviceList();
          await this.authService.getAllDevices(this.userData.UId, true);
          this.spinner.hide();
          this.toastrService.success(
            'Device Deleted Successfully!',
            'Deleted!'
          );
        } else {
          this.spinner.hide();
          this.toastrService.warning('Something Went Wrong!', 'Oops!');
        }
      });
  }

  clearDeviceData() {
    this.deviceData = {
      DId: null,
      Date: '',
      Device_MAC: '',
      Device_Model: '',
      Device_Name: '',
      Location: '',
      SC_Name: '',
      Time: '',
      UId: '',
    };
  }
}
