import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/services/auth.service';
import { SignUp } from 'src/app/models/sigup';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-audit-report',
  templateUrl: './audit-report.component.html',
  styleUrls: ['./audit-report.component.css']
})
export class AuditReportComponent implements OnInit {

  file: File = null;
  users=[];
  filelist=[];
  hasRecord=false;
  selectedUser="";
  userData: SignUp;
  isAdmin=false;

  constructor(private authService: AuthService,private toastrService: ToastrService, private spinner: NgxSpinnerService, private commanService:CommonService) { }

  ngOnInit(): void {
    this.userData = JSON.parse(sessionStorage.getItem(this.authService.USER));
    if(this.userData.UId=="STbVk0")
      {
        this.isAdmin=true;
        this.getUsers();
      }
      else{
        this.isAdmin=false;
        this.getuserreportsforusers(this.userData.UId)
      }
   
  }

  onUserSelected(event)
  {
    if (event.target.value != 0) {
      this.selectedUser = event.target.value;
      this.getuserreports();
    }
  }

  getuserreports()
  {
    this.commanService.getUserReports(this.selectedUser).then((res) => {
      //console.log(res.result.recordset);
      this.filelist = res.result.recordset;
      if(this.filelist.length>0)
        {
          this.hasRecord=true;
        }
        else{
          this.hasRecord=false;
        }
    })
    .catch((err) => {
      this.toastrService.warning('Something Went Wrong!', 'Oops!');
    });
  }

  getuserreportsforusers(uid:string)
  {
    this.commanService.getUserReports(uid).then((res) => {
      //console.log(res.result.recordset);
      this.filelist = res.result.recordset;
      if(this.filelist.length>0)
        {
          this.hasRecord=true;
        }
        else{
          this.hasRecord=false;
        }
    })
    .catch((err) => {
      this.toastrService.warning('Something Went Wrong!', 'Oops!');
    });
  }

  onFilechange(event: any) {
    if(event.target.files[0].type=='application/pdf')
      {
        if(event.target.files[0].size<=250000)
          {
            this.file = event.target.files[0];
          }
          else{
            
            this.toastrService.error(
              'Exceeded file size limit!',
              'Error!'
            );
          }
      }
      else{
        this.toastrService.error(
          'Invalid File format!',
          'Error!'
        );
      }    
  }

  upload() {
    if(this.selectedUser!='')
      {
        if (this.file) { 
          this.commanService.uploadfile(this.file,this.selectedUser).subscribe(resp => {
            if(resp.success==true)
              {
                this.getuserreports();
                this.toastrService.success(
                  'File uploaded successfully!',
                  'Success!'
                );
              }
              else{
                this.toastrService.warning('Something Went Wrong!', 'Oops!');
              }
          })
        } else {      
            this.toastrService.error(
              'Please select a file!',
              'Error!'
            );
        }
      }
      else
      {
        this.toastrService.error(
          'Please select a user!',
          'Error!'
        );
      }
  }

  getUsers()
  {
    this.spinner.show();
    this.commanService.getallUsers().then((res) => {
      //console.log(res.name.recordset);
      this.users = res.name.recordset;
    })
    .catch((err) => {
      this.toastrService.warning('Something Went Wrong!', 'Oops!');
    });
  }

  deleteRow(id:any,fname:any){
    this.spinner.show();
    this.commanService.removeAuditFile(id,fname).then((res) => {
      if(res.success==true)
        {
          this.getuserreports();
        this.toastrService.success(
          'File removed successfully!',
          'Success!'
        );
      }
      else{
        this.toastrService.warning('Something Went Wrong!', 'Oops!');
      }
    })
    .catch((err) => {
      this.toastrService.warning('Something Went Wrong!', 'Oops!');
    });
  }

  ViewFile(x:any)
  {
    let res='http://upload.microgo-smarthygiene.in/AuditReport/uploads/'+x;
    //const fileURL = URL.createObjectURL(res);
    window.open(res, '_blank');
  }

}
