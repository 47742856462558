import { CustomReportRes } from './../models/customReportDetails';
import { ReportDetails } from './../models/reportDetails';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '../Common/config';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  allReportDetails: ReportDetails[];
  maxColumn = [];
  dates = [];

  constructor(private http: HttpClient) {}

  getReports(requestBody) {
    return this.http.post<any>(`${config.apiUrl}/reports`, requestBody);
  }

  getReportsforwm4G9k(requestBody) {
    return this.http.post<any>(`${config.apiUrl}/reportsforwm4G9k`, requestBody);
  }

  

  getAllReports(data: any) {
    return this.http.post<any>(`${config.apiUrl}/allReports`, data);
  }

  getTotalSwipe(data: any) {
    return this.http.post<any>(`${config.apiUrl}/totalSwipeAndDates`, data);
  }

  getMaxSOP(UId: string) {
    return this.http.get<any>(`${config.apiUrl}/user-regp/max-sop?UId=${UId}`);
  }

  getLocationAndDevices(UId: any) {
    return this.http.get<any>(
      `${config.apiUrl}/user-datap-getLoactionAndDeviceNames?UId=${UId}`
    );
  }

  getCustomReportDropdown(UId: any) {
    return this.http.get<any>(
      `${config.apiUrl}/customReportsDropdown?SC_Name=${UId}`
    );
  }

  getCustomReportMonthList(UId: any) {
    return this.http.get<any>(
      `${config.apiUrl}/custom-report-monthList?UId=${UId}`
    );
  }

  getCustomReport(requestBody: any) {
    return this.http.post<CustomReportRes>(
      `${config.apiUrl}/customReports`,
      requestBody
    );
  }

  getCustomMonthlyReport(requestBody: any) {
    return this.http.post<CustomReportRes>(
      `${config.apiUrl}/customMonthlyReport`,
      requestBody
    );
  }

  getShiftUsers(UId: any) {
    return this.http.get<any>(`${config.apiUrl}/shift-user?SC_Name=${UId}`);
  }

  deleteShiftUser(data: any) {
    return this.http.post<any>(`${config.apiUrl}/delete-shift-user`, data);
  }

  //automailer
  sendautomailer(data: any) {
    return this.http.post<any>(`${config.apiUrl}/sendMailReport`, data);
  }
}
