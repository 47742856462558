import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserGuard implements CanActivate, CanLoad {
  constructor(private authService: AuthService, private router: Router) {}

  devices: any;
  routeURL: string;
  hasDevice: boolean = false;

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.routeURL = state.url;
    return this.canLoad();
  }

  canLoad() {
    this.devices = JSON.parse(sessionStorage.getItem(this.authService.DEVICES));
    if (
      this.routeURL == '/dashboard' ||
      this.routeURL == '/report' ||
      this.routeURL == '/custom-report'
    ) {
      this.hasDevice = this.devices.P_Device;
    } else if (
      this.routeURL == '/dashboard-k' ||
      this.routeURL == '/k-report'
    ) {
      this.hasDevice = this.devices.K_Device;
    } else if (
      this.routeURL == '/dashboard-o' ||
      this.routeURL == '/o-cards' ||
      this.routeURL == 'o-report' ||
      this.routeURL == 'o-new-report'
    ) {
      this.hasDevice = this.devices.O_Device;
    } else if (
      this.routeURL == '/dashboard-lite-plus' ||
      this.routeURL == '/lite-plus-cards' ||
      this.routeURL == '/lite-plus-report'
    ) {
      this.hasDevice = this.devices.LitePlus_Device;
    }

    // return this.hasDevice;

    if (!this.hasDevice) {
      console.log('home route :>> ');
      this.router.navigate(['/']);
    } else {
      return this.hasDevice;
    }
  }
}
