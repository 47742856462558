import {
  CustomReportRecord,
  CustomReportDeptData,
  ShiftUserReg,
} from './../../models/customReportDetails';
import { ReportService } from '../../services/report-service.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SignUp } from 'src/app/models/sigup';
import { AuthService } from 'src/app/auth/services/auth.service';
import {
  ReportDetails,
  ReportDetailsFinalized,
} from 'src/app/models/reportDetails';
import { CommonService } from 'src/app/services/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Chart, registerables } from 'chart.js';
import { HasDevice } from '../../models/hasDevice';

Chart.register(...registerables);

const pdfMake = require('pdfmake/build/pdfmake.js');
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { AttendanceService } from 'src/app/services/attendance.service';
import { ToastrService } from 'ngx-toastr';
import { promises } from 'dns';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-report',
  templateUrl: './p-custom-report.component.html',
  styleUrls: ['./p-custom-report.component.css'],
})
export class CustomReportComponent implements OnInit {
  userData: SignUp;
  totalSwipe;
  map = new Map();
  dropdownArray = [];
  dateFilteredAllUser = [];
  finalReportDetails: ReportDetailsFinalized[];
  AllReportDetails: ReportDetails[];
  maxColumn = [];
  selectedDate;
  dtOptions: any = {};
  dates = [];
  RFID_No;
  finalArray = [];
  onSubmitClick = false;
  showButton = false;
  toggleAll = false;
  selectedModel = '';
  hasReportData: boolean = false;
  complianceReport: any = [];
  chartDataURL: any = '';
  showDownloadButton = false;
  metadata: any = {};
  devices: HasDevice;
  deviceNames: string[];
  hasPLiteDevice = false;
  SOP: number = 8;
  deviceLocations = [];
  deviceDepartments = [];
  devicesDevice = [];
  shiftData = [];
  modelData=[];
  selectedLocation: string = '';
  selectedDevice: string = '';
  selectedDepartment: string = '';
  selectedCard: string = '';
  selectedShift: string = '';
  customReportDeptData: CustomReportDeptData[] = [];
  customReportRecords: CustomReportRecord[] = [];
  userReg: ShiftUserReg;
  shiftUsers: ShiftUserReg[] = [];
  addUserToggle: boolean = true;
  editUserToggle: boolean = false;
  viewUsers: boolean = false;
  columns: any[] = [];

  constructor(
    public reportService: ReportService,
    private authService: AuthService,
    private chRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private attendanceService: AttendanceService,
    private toastrService: ToastrService
  ) {
    this.userData = new SignUp();
    this.devices = new HasDevice();
    this.userReg = new ShiftUserReg();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.resetVariables();
    this.userData = JSON.parse(sessionStorage.getItem(this.authService.USER));
    this.devices = JSON.parse(sessionStorage.getItem(this.authService.DEVICES));
    this.deviceNames = this.devices.deviceNames;
    if (
      this.deviceNames.includes('P Lite') &&
      !this.deviceNames.includes('P Max')
    )
      this.hasPLiteDevice = true;
    this.getDropdownValues();
    this.getShiftUsers();
    this.chRef.detectChanges();
    this.spinner.hide();
  }

  resetVariables() {
    this.maxColumn = [];
    this.onSubmitClick = false;
    this.selectedDate = '';
    this.selectedModel = '';
    this.showButton = false;
  }

  getDropdownValues() {
    this.reportService
      .getCustomReportDropdown('sc_' + this.userData.UId)
      .subscribe((res) => {
        console.log('res :>> ', res.data);
        const {
          dateData,
          departmentData,
          deviceData,
          shiftData,
          modelData,
          locationData,
        } = res.data;
        this.dates = dateData;
        this.deviceLocations = locationData;
        this.deviceDepartments = departmentData;
        this.devicesDevice = deviceData;
        this.shiftData = shiftData;
        this.modelData=modelData;
      });
  }

  getShiftUsers() {
    this.reportService
      .getShiftUsers('sc_' + this.userData.UId)
      .subscribe((res) => {
        this.shiftUsers = res.data;
        this.commonService.loadReportDatatables('dataTableShiftUsers');
        this.chRef.detectChanges();
      });
  }

  clearData() {
    this.userReg.Name = '';
    this.userReg.Dept = '';
    this.userReg.No_Of_User = '';
    this.userReg.RFID_No = null;
    this.userReg.Preset_HHP = null;
    this.editUserToggle = false;
    this.addUserToggle = true;
  }

  addUser() {
    this.userReg.SC_Name = 'sc_' + this.userData.UId;
    this.userReg.UId = this.userData.UId;

    this.attendanceService.addShiftUser(this.userReg).subscribe({
      next: (res) => {
        console.log('res :>> ', res);
        this.clearData();
        this.commonService.destroyDatatbles('dataTableShiftUsers');
        this.toastrService.success('Member Added Successfully!', 'Added!');
        this.getShiftUsers();
      },
      error: (err) => {
        this.clearData();
        this.toastrService.warning('Something Went Wrong!', 'Oops!');
      },
    });
  }

  async getReportList() {
    this.spinner.show();
    if (this.onSubmitClick) {
      this.commonService.destroyDatatbles('dataTableAllUser');
      this.commonService.destroyDatatbles('dataTableAllRecords');
    }
    // this.chRef.detectChanges();
    const requestBody = {
      SC_Name: `sc_${this.userData.UId}`,
      date: this.selectedDate,
      rfid: this.selectedCard,
      location: this.selectedLocation,
      deviceId: this.selectedDevice,
      dept: this.selectedDepartment,
      shift: this.selectedShift,
    };
    await this.reportService.getCustomReport(requestBody).subscribe({
      next: async (res) => {
        this.metadata = res.data.metadata;
        this.customReportDeptData = res.data.deptData;
        this.customReportRecords = res.data.records;
        this.onSubmitClick = true;

        let graphLabels = this.customReportDeptData.map(({ Dept }) => Dept);
        let graphData = this.customReportDeptData.map(
          ({ Compliance }) => Compliance
        );

        this.chRef.detectChanges();
        if (
          this.complianceReport &&
          this.complianceReport != null &&
          !Array.isArray(this.complianceReport)
        )
          this.complianceReport.destroy();

        this.fillComplianceChart(graphLabels, graphData);

        this.loadDatatbles();
        this.chRef.detectChanges();
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
      },
      
    });
  }

  poolColors(graphData: []) {
    let colours = [];
    let colors = {
      blue: 'rgba(0,0,255,1)',
      green: 'rgba(0, 255, 0,1)',
      red: 'rgba(255,0,0,1)',
    };
    for (let i = 0; i < graphData.length; i++) {
      if (Number(graphData[i]) >= 80) {
        colours.push(colors.green);
      } else if (Number(graphData[i]) > 25) {
        colours.push(colors.blue);
      } else {
        colours.push(colors.red);
      }
    }
    return colours;
  }

  fillComplianceChart(graphLabel, graphData) {
    this.complianceReport = new Chart('complianceReport', {
      type: 'bar',
      data: {
        labels: graphLabel,
        datasets: [
          {
            // label: 'Compliance (%)',
            // backgroundColor: 'rgba(0,0,255,0.5)',
            backgroundColor: this.poolColors(graphData),
            barPercentage: 0.5,
            barThickness: 30,
            maxBarThickness: 50,
            minBarLength: 2,
            hoverBackgroundColor: 'rgba(0,0,255,0.7)',
            borderColor: 'rgba(0,0,255,1)',
            data: graphData,
          },
        ],
      },
      options: {
        devicePixelRatio: 1.5,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 25,
            top: 25,
            bottom: 0,
          },
        },
        scales: {
          xAxes: {
            title: {
              text: 'Deparment Names',
              display: true,
            },
            time: {
              unit: 'day',
            },
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              maxTicksLimit: 8,
            },
          },
          yAxes: {
            title: {
              text: 'Hand Hygiene Compliance %',
              display: true,
            },
            ticks: {
              minRotation: 0,
              maxRotation: 100,
              maxTicksLimit: 6,
              padding: 10,
              // Include a dollar sign in the ticks
              // callback: function(value, index, values) {
              //     return '$' + number_format(value);
              // }
            },
            grid: {
              color: 'rgb(234, 236, 244)',
              drawBorder: false,
              borderDash: [2],
            },
          },
        },
        plugins: {
          legend: {
            display: false,
            position: 'top',
            title: {
              text: 'Compliance(%)',
              color: '#000000',
              display: true,
            },
          },
          tooltip: {
            titleMarginBottom: 10,
            titleColor: '#6e707e',
            titleFont: {
              size: 14,
            },
            backgroundColor: 'rgb(255,255,255)',
            bodyColor: '#858796',
            borderColor: '#dddfeb',
            borderWidth: 1,
            padding: 15,
            displayColors: false,
            caretPadding: 10,
            /* callbacks: {
                  label: function (context) {
                    return `${Number(context.parsed.y)}`;
                  },
                }, */
          },
        },
      },
    });
    this.spinner.hide();
  }

  loadDatatbles() {
    this.commonService.loadReportDatatables('dataTableAllUser');
    this.commonService.loadReportDatatables('dataTableAllRecords');
  }

  onOptionsSelected(event) {
    if (event.target.value != 0) {
      this.selectedDate = event.target.value;
      this.showButton = this.selectedDate ? true : false;
    }
  }

  onModelSelected(event) {
    this.selectedModel = event.target.value;
  }

  viewShiftUsers() {
    this.viewUsers = !this.viewUsers;
    this.chRef.detectChanges();
    if (this.viewUsers) {
      this.commonService.destroyDatatbles('dataTableAllUser');
      this.commonService.destroyDatatbles('dataTableAllRecords');
      this.commonService.loadReportDatatables('dataTableShiftUsers');
    } else {
      this.commonService.loadReportDatatables('dataTableAllUser');
      this.commonService.loadReportDatatables('dataTableAllRecords');
      this.commonService.destroyDatatbles('dataTableShiftUsers');
    }
  }

  onDelete(Id: any) {
    const body = {
      SC_Name: 'sc_' + this.userData.UId,
      Id,
    };
    this.reportService.deleteShiftUser(body).subscribe({
      next: (res) => {
        this.clearData();
        this.commonService.destroyDatatbles('dataTableShiftUsers');
        this.toastrService.success('Member Removed Successfully!', 'Removed!');
        this.getShiftUsers();
      },
      error: (err) => {
        this.clearData();
        this.toastrService.warning('Something Went Wrong!', 'Oops!');
      },
    });
  }

  onShiftSelected(event) {
    this.selectedShift = event.target.value;
  }

  onDepartmentSelected(event) {
    this.selectedDepartment = event.target.value;
  }

  onLocationSelected(event) {
    this.selectedLocation = event.target.value;
  }

  onDeviceSelected(event) {
    this.selectedDevice = event.target.value;
  }

  getDocumentDefinition() {
    let rows = [];

    this.columns = [
      '#',
      'Department',
      'Present HHP\n[Shift Wise]',
      'No of Users',
      'Expected SOP',
      'Total\ncomplete usage',
      'Total\nincomplete usage',
      'Compliance',
      'Date',
    ];
    let finalColumns = this.columns.map((value) => {
      return { text: value, alignment: 'center', style: 'header' };
    });

    let goAssureHeader = [];
    goAssureHeader.push({
      text: [
        'GO',
        {
          text: 'assure',
          italics: true,
        },
        ' - SMART Hand Hygiene Station',
      ],
      colSpan: this.columns.length,
      style: 'headerBig',
      alignment: 'center',
    });

    let footerData = [];
    footerData.push({
      columns: [
        {
          text: [
            { text: '   ', background: '#ff0000' },
            ' : Compliance (%) less than 25',
          ],
          fontSize: 8,
        },
        {
          text: [
            { text: '   ', background: '#0000ff' },
            ' : Compliance (%) between 25 to 80',
          ],
          fontSize: 8,
        },
        {
          text: [
            { text: '   ', background: '#00ff00' },
            ' : Compliance (%) greater than equal to 80',
          ],
          fontSize: 8,
        },
      ],
      alignment: 'center',
    });

    for (let i = 1; i < this.columns.length; i++) {
      goAssureHeader.push({ text: '' });
    }

    let width = (100 / finalColumns.length).toFixed(2),
      widths = [];

    for (let i = 0; i < finalColumns.length; i++) {
      widths.push(`${width}%`);
    }

    let singleRow = [],
      singleRecord: CustomReportDeptData = null,
      background = '';

    for (let i = 0; i < this.customReportDeptData.length; i++) {
      singleRecord = this.customReportDeptData[i];
      singleRow = [];
      singleRow.push({
        text: i + 1,
        fontSize: 6,
        alignment: 'center',
      });
      singleRow.push({
        text: singleRecord.Dept,
        fontSize: 6,
        alignment: 'center',
      });
      singleRow.push({
        text: singleRecord.Preset_HHP,
        fontSize: 6,
        alignment: 'center',
      });
      singleRow.push({
        text: singleRecord.No_Of_User,
        fontSize: 6,
        alignment: 'center',
      });

      singleRow.push({
        text: singleRecord.ExpectedSOP,
        fontSize: 6,
        alignment: 'center',
      });
      singleRow.push({
        text: singleRecord.totalCompleted,
        fontSize: 6,
        alignment: 'center',
      });
      singleRow.push({
        text: singleRecord.totalIncompleted,
        fontSize: 6,
        alignment: 'center',
      });
      singleRow.push({
        text: singleRecord.Compliance,
        fontSize: 6,
        alignment: 'center',
      });
      singleRow.push({
        text: singleRecord.Date,
        fontSize: 6,
        alignment: 'center',
      });

      rows.push(singleRow);
    }

    let firstRow = [],
      secondRow = [],
      thirdRow = [];

    const metadata = this.metadata;

    firstRow = [
      {
        text: `Company/Location: ${this.userData.Company_Name}, ${this.userData.Location}`,
        colSpan: 3,
        alignment: 'center',
        fontSize: 8,
        border: [true, false, false, false],
      },
      {
        text: '',
      },
      {
        text: '',
      },
      {
        text: `Water saving today: ${metadata.waterSavingToday} L`,
        colSpan: 3,
        alignment: 'center',
        fontSize: 8,
        border: [false, false, false, false],
      },
      {
        text: '',
      },
      {
        text: '',
      },
      {
        text: `Consumable usage today: ${metadata.consumableToday} L`,
        colSpan: 3,
        alignment: 'center',
        fontSize: 8,
        border: [false, false, true, false],
      },
      {
        text: '',
      },
      {
        text: '',
      },
    ];

    secondRow = [
      {
        text: `Total usage today: ${metadata.totalUsageToday}`,
        colSpan: 3,
        alignment: 'center',
        fontSize: 8,
        border: [true, false, false, false],
      },
      {
        text: '',
      },
      {
        text: '',
      },
      {
        text: `Total complete usage today: ${metadata.totalCompletedUsageToday}`,
        colSpan: 3,
        alignment: 'center',
        fontSize: 8,
        border: [false, false, false, false],
      },
      {
        text: '',
      },
      {
        text: '',
      },
      {
        text: `Total incomplete usage today: ${metadata.totalIncompletedUsageToday}`,
        colSpan: 3,
        alignment: 'center',
        fontSize: 8,
        border: [false, false, true, false],
      },
      {
        text: '',
      },
      {
        text: '',
      },
    ];

    thirdRow = [
      {
        text: `Date: ${metadata.date}`,
        colSpan: 3,
        alignment: 'center',
        fontSize: 8,
        border: [true, false, false, false],
      },
      {
        text: '',
      },
      {
        text: '',
      },
      {
        text: `Average Compliance: ${metadata.averageCompliance}`,
        colSpan: 3,
        alignment: 'center',
        fontSize: 8,
        border: [false, false, false, false],
      },
      {
        text: '',
      },
      {
        text: '',
      },
      {
        text: `SOP definition: Based on Shift Wise`,
        colSpan: 3,
        alignment: 'center',
        fontSize: 8,
        border: [false, false, true, false],
      },
      {
        text: '',
      },
      {
        text: '',
      },
    ];

    return {
      info: {
        title: 'Micro GO - P-Custom-Report',
        author: 'Micro GO LLP',
        subject: 'P-Custom-Report',
      },
      pageOrientation: 'landscape',
      content: [
        {
          style: 'headerTable',
          table: {
            widths: ['35%', '35%', '34%', '0%', '0%', '0%'],
            body: [
              [
                {},
                {
                  text: 'MicroGO | P Custom-Report',
                  style: 'headerBig',
                  alignment: 'center',
                  margin: [0, 5, 0, 0],
                },
                {},
              ],
            ],
          },
          layout: 'noBorders',
        },
        { text: '', margin: [0, 5, 0, 0] },
        {
          style: 'tableExample',
          table: {
            widths: widths,
            body: [
              goAssureHeader,
              firstRow,
              secondRow,
              thirdRow,
              finalColumns,
              ...rows,
            ],
          },
          layout: {
            fillColor: function (rowIndex: any) {
              return rowIndex >= 2 && rowIndex % 2 === 0 ? '#f0eded' : null;
            },
          },
        },
        {
          table: {
            widths: ['*'],
            body: [
              [
                {
                  image: 'chart',
                  fit: [500, 500],
                  alignment: 'center',
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: ['*'],
            body: [footerData],
          },
        },
        {
          text: '**only Set SOP data will be visible , above SOP usage count can be checked in the total count . For details check "View all reports" in the website.',
          fontSize: 8,
          alignment: 'center',
          margin: [0, 0, 0, 3],
        },
        {
          text: 'Please note: Overall Compliance % on the dashboard  is calculated as complete vs Incomplete cycle whereas on the daily report, the compliance % is reported as defined by the SOP required by you',
          fontSize: 8,
          alignment: 'center',
        },
      ],
      images: {
        chart: this.chartDataURL,
      },
      styles: {
        headerBig: {
          fontSize: 14,
          bold: true,
          alignment: 'justify',
          margin: [0, 5, 0, 5],
        },
        header: {
          fontSize: 6,
          bold: true,
          alignment: 'justify',
          margin: [0, 5, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
          alignment: 'justify',
        },
        headerTable: {
          alignment: 'justify',
        },
      },
    };
  }

  async convertHtmlToImageDataURL():Promise<void> {
    this.chartDataURL =await this.complianceReport.toBase64Image();
  }

  async downloadReport() {
    await this.convertHtmlToImageDataURL();
    pdfMake
      .createPdf(this.getDocumentDefinition())
      .download(`MicroGo P-Custom-Report ${this.selectedDate}`);
  }
  
}
