import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-lobby',
  templateUrl: './lobby.component.html',
  styleUrls: ['./lobby.component.css'],
})
export class LobbyComponent implements OnInit {
  loginImageCollection: Array<Object> = [
    {
      image: 'assets/img/slider-images/IMG-20210505-WA0024.jpg',
      thumbImage: 'assets/img/slider-images/IMG-20210505-WA0024.jpg',
      alt: 'Right Way?',
      // title: 'Image 1',
    },
    {
      image: 'assets/img/slider-images/IMG-20210505-WA0029.jpg',
      thumbImage: 'assets/img/slider-images/IMG-20210505-WA0029.jpg',
      alt: 'Counts',
      // title: 'Image 1',
    },
    {
      image: 'assets/img/slider-images/IMG-20210505-WA0030.jpg',
      thumbImage: 'assets/img/slider-images/IMG-20210505-WA0030.jpg',
      alt: 'Service',
      // title: 'Image 1',
    },
    {
      image: 'assets/img/slider-images/IMG-20210505-WA0031.jpg',
      thumbImage: 'assets/img/slider-images/IMG-20210505-WA0031.jpg',
      alt: 'GOassure',
      // title: 'Image 1',
    },
    {
      image: 'assets/img/slider-images/IMG-20210505-WA0027.jpg',
      thumbImage: 'assets/img/slider-images/IMG-20210505-WA0027.jpg',
      alt: 'Contact',
      // title: 'Image 1',
    },
  ];

  constructor(private toastrService: ToastrService) {}

  ngOnInit(): void {
    
    console.log("Version - 1.0.1.0");
  }

  onExperience() {
    // window.location.href = 'https://www.microgo.in/products-1';
    (window as any).open('https://www.microgo.in/products-1', '_blank');

    /* this.toastrService.success(
      'This Functionality Will Be Availabe Soon!',
      'Coming Soon!'
    ); */
  }
}
