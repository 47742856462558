import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '../Common/config';

@Injectable({
  providedIn: 'root',
})
export class KServiceService {
  constructor(private http: HttpClient) {}

  getDates(UId: any) {
    return this.http
      .get<any>(`${config.apiUrl}/getDatesK?UId=${UId}`)
      .toPromise();
  }

  getTotalSwipe(UId: any) {
    return this.http
      .get<any>(`${config.apiUrl}/getTotalSwipeK?UId=${UId}`)
      .toPromise();
  }

  getReportsByDateAndModel(UId: any, Date: any, Device_Model: any) {
    return this.http
      .get<any>(
        `${config.apiUrl}/getDeviceK?UId=${UId}&Date=${Date}&Device_Model=${Device_Model}`
      )
      .toPromise();
  }

  getAllReports(UId: any) {
    return this.http
      .get<any>(`${config.apiUrl}/k-device/getAllReport?UId=${UId}`)
      .toPromise();
  }

  getMonthlyComplianceReports(UId: any) {
    return this.http
      .get<any>(`${config.apiUrl}/k-device/getMonthlyReport?UId=${UId}`)
      .toPromise();
  }

  getWeeklyReport(data) {
    return this.http
      .post<any>(`${config.apiUrl}/k-device/weeklyReport`, data)
      .toPromise();
  }

  getTopDevice(UId: any) {
    return this.http
      .get<any>(`${config.apiUrl}/k-device/topDevices?UId=${UId}`)
      .toPromise();
  }
}
