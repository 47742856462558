import { CreateAccountService } from './../../services/create-account.service';
import { Component, OnInit } from '@angular/core';
import { SignUp } from 'src/app/models/sigup';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Router } from '@angular/router';
import { Chart, registerables } from 'chart.js';
import { UserProfileService } from './../../services/user-profile.service';
import { ChangeDetectorRef } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { KServiceService } from 'src/app/services/k-service.service';
import { WeeklyReport } from '../../models/weeklyReport';
import { NgxSpinnerService } from 'ngx-spinner';

Chart.register(...registerables);

@Component({
  selector: 'app-k-dashboard',
  templateUrl: './k-dashboard.component.html',
  styleUrls: ['./k-dashboard.component.css'],
})
export class KDashboardComponent implements OnInit {
  userData: SignUp;
  now: string;
  date: string;
  totalSwipe: number;
  totalABHR: any;
  completed: number;
  incompleted: number;
  todaySwipe: number;
  complianceLevel: any;
  allTimeHeroes: any = [];
  todayHeroes: any = [];
  weeklyReport: any = [];
  chart: any = [];
  chartDailySwipe: any = [];
  topDevices = [];
  data: WeeklyReport[];
  graphLabel = [];
  graphData = [];
  hasMonthlyData: boolean;

  constructor(
    private commonService: CommonService,
    private kDeviceService: KServiceService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private chRef: ChangeDetectorRef,
    private router: Router
  ) {
    this.userData = new SignUp();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.userData = JSON.parse(sessionStorage.getItem(this.authService.USER));
    this.now = new Date()
      .toLocaleTimeString('en-US', { hour12: false })
      .slice(0, 5);
    this.date = new Date().toDateString();
    this.getTotalCount();
    this.getAllTimeHeroes();
    this.getTodayHeroes();
    this.getMonthlyReport();
    this.getWeeklyReport();
    this.getTopDevices();
    this.chRef.detectChanges();
    this.spinner.hide();
  }

  getTotalCount() {
    this.commonService
      .getTotalCountForK(this.userData.UId)
      .then((res) => {
        this.spinner.show();
        let { TotalSwipe, Completed, Incompleted, TodaySwipe } = res.data;
        this.totalSwipe = TotalSwipe || 0;
        this.completed = Completed || 0;
        this.incompleted = Incompleted || 0;
        this.todaySwipe = TodaySwipe || 0;

        this.totalABHR = (Number(this.totalSwipe) * 2).toFixed(2) || 0;
        this.complianceLevel =
          ((this.completed / this.totalSwipe) * 100).toFixed(2) || '0';

        this.complianceLevel = isNaN(this.complianceLevel)
          ? 0
          :this.complianceLevel>100?100:this.complianceLevel;

        this.spinner.hide();
      })
      .catch((error) => {
        this.spinner.show();
        console.log(error);
        this.spinner.hide();
      });
  }

  getWeeklyReport() {
    let graphLabel = [];
    let graphData = [];
    this.kDeviceService
      .getWeeklyReport({ SC_Name: 'sc_' + this.userData.UId })
      .then((res) => {
        res = res.data;
        this.spinner.show();
        //let data: WeeklyReport[] = res as WeeklyReport[];
        let splitDate: string[];
        let j = 0;
        for (let i = res.length - 1; i >= 0; i--) {
          this.weeklyReport.push(res[i]);
          splitDate = this.weeklyReport[j]['Date'].split('-');
          this.weeklyReport[j]['Date'] = splitDate[2] + '/' + splitDate[1];
          splitDate.slice(0, splitDate.length);

          graphLabel.push(this.weeklyReport[j]['Date']);
          graphData.push(this.weeklyReport[j]['Total_Swipe']);
          j = j + 1;
        }

        this.chartDailySwipe = new Chart('canvasDailySwipe', {
          type: 'bar',
          data: {
            labels: graphLabel,
            datasets: [
              {
                label: 'Daily Usage',
                backgroundColor: '#263070',
                barPercentage: 0.5,
                barThickness: 30,
                minBarLength: 2,
                hoverBackgroundColor: '#2e59d9',
                borderColor: '#4e73df',
                data: graphData,
                yAxisID: 'yAxes',
                xAxisID: 'xAxes',
                maxBarThickness: 50,
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
            layout: {
              padding: {
                left: 10,
                right: 25,
                top: 25,
                bottom: 0,
              },
            },
            scales: {
              xAxes: {
                title: {
                  text: 'Date',
                  display: true,
                },
                time: {
                  unit: 'day',
                },
                grid: {
                  display: false,
                  drawBorder: false,
                },
                ticks: {
                  maxTicksLimit: 8,
                },
              },
              yAxes: {
                title: {
                  text: 'Usage Count',
                  display: true,
                },
                ticks: {
                  minRotation: 0,
                  maxRotation: 100,
                  maxTicksLimit: 6,
                  padding: 10,
                },
                grid: {
                  color: (context) =>
                    context.tick.value == 0
                      ? 'rgb(234, 236, 244)'
                      : 'rgb(234, 236, 244)',
                  drawBorder: false,
                  borderDash: [2],
                },
              },
            },
            plugins: {
              legend: {
                display: false,
                position: 'top',
              },
              tooltip: {
                titleMarginBottom: 10,
                titleColor: '#6e707e',
                titleFont: {
                  size: 14,
                },
                backgroundColor: 'rgb(255,255,255)',
                bodyColor: '#858796',
                borderColor: '#dddfeb',
                borderWidth: 1,
                padding: 15,
                displayColors: false,
                caretPadding: 10,
                callbacks: {
                  label: (context) => {
                    return `${Number(context.parsed.y)}`;
                  },
                },
              },
            },
          },
        });
        this.spinner.hide();
      })
      .catch((error) => {
        this.spinner.show();
        console.log(error);
        this.spinner.hide();
      });
  }

  getMonthlyReport() {
    this.spinner.show();
    this.kDeviceService
      .getMonthlyComplianceReports(this.userData.UId)
      .then((res) => {
        if (res.data && res.data.length) {
          this.hasMonthlyData = true;
          this.chRef.detectChanges();
          let result = [...res.data];

          this.graphData = result.map((obj) => {
            return obj.Compliance;
          });
          this.graphLabel = result.map((obj) => {
            return obj.Date;
          });

          this.chart = new Chart('canvas', {
            type: 'bar',
            data: {
              labels: this.graphLabel,
              datasets: [
                {
                  label: 'Compliance',
                  backgroundColor: '#263070',
                  borderColor: 'rgba(78, 115, 223, 1)',
                  hoverBackgroundColor: '#2e59d9',
                  minBarLength: 2,
                  data: this.graphData,
                  yAxisID: 'yAxes',
                  xAxisID: 'xAxes',
                },
              ],
            },
            options: {
              maintainAspectRatio: false,
              layout: {
                padding: {
                  left: 10,
                  right: 25,
                  top: 25,
                  bottom: 0,
                },
              },
              scales: {
                xAxes: {
                  title: {
                    text: 'Date',
                    display: true,
                  },
                  time: {
                    unit: 'day',
                  },
                  grid: {
                    display: false,
                    drawBorder: false,
                  },
                },
                yAxes: {
                  beginAtZero: true,
                  title: {
                    text: 'Compliance %',
                    display: true,
                  },
                  ticks: {
                    minRotation: 0,
                    maxRotation: 100,
                    maxTicksLimit: 6,
                    padding: 10,
                  },
                  grid: {
                    color: 'rgb(234, 236, 244)',
                    drawBorder: false,
                    borderDash: [2],
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                  position: 'top',
                },
                tooltip: {
                  backgroundColor: 'rgb(255,255,255)',
                  bodyColor: '#858796',
                  titleMarginBottom: 10,
                  titleColor: '#6e707e',
                  titleFont: {
                    size: 14,
                  },
                  borderColor: '#dddfeb',
                  borderWidth: 1,
                  padding: 15,
                  displayColors: false,
                  intersect: false,
                  mode: 'index',
                  caretPadding: 10,
                  callbacks: {
                    label: (context) => {
                      return `Compliance : ${Number(context.parsed.y)} %`;
                    },
                  },
                },
              },
            },
          });
          this.spinner.hide();
        } else {
          this.hasMonthlyData = false;
          this.chRef.detectChanges();
        }
        this.spinner.hide();
      })
      .catch((err) => {
        this.spinner.show();
        console.log(err);
        this.spinner.hide();
      });
  }

  getTopDevices() {
    this.kDeviceService
      .getTopDevice(this.userData.UId)
      .then((res) => {
        this.spinner.show();
        this.topDevices = res?.data ? res.data : [];
        this.spinner.hide();
      })
      .catch((error) => {
        this.spinner.show();
        console.log(error);
        this.spinner.hide();
      });
  }

  getAllTimeHeroes() {
    this.commonService
      .getAllTimeHeroes(this.userData.UId)
      .then((res) => {
        this.spinner.show();
        this.allTimeHeroes = res?.data ? res.data : [];
        this.spinner.hide();
      })
      .catch((error) => {
        this.spinner.show();
        console.log(error);
        this.spinner.hide();
      });
  }

  getTodayHeroes() {
    this.commonService
      .getTodayHeroes({ SC_Name: 'sc_' + this.userData.UId })
      .subscribe((res) => {
        this.spinner.show();
        this.todayHeroes = [...res];
        this.spinner.hide();
      });
  }
}
